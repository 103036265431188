import { useLocation, useNavigate } from "react-router-dom";
import { checkPlatform, isKiikeyApp } from "App-config";

import cooking from "src_assets/progress-cooking.png";
import delivery from "src_assets/progress-delivery.png";
import holding from "src_assets/progress-holding-y.png";
import done from "src_assets/progress-done.png";

import delivery_waiting_icon from "src_assets/m-1.png";
import delivery_waiting_icon_2 from "src_assets/m-2.png";
import delivery_waiting_icon_3 from "src_assets/m-3.png";
import delivery_waiting_icon_4 from "src_assets/m-4.png";
import delivery_waiting_icon_on from "src_assets/m-1-on.png";
import delivery_waiting_icon_on_2 from "src_assets/m-2-on.png";
import delivery_waiting_icon_on_3 from "src_assets/m-3-on.png";
import delivery_waiting_icon_on_4 from "src_assets/m-4-on.png";
import delivery_main_img from "src_assets/progress-main-img.png";

import packing_waiting_icon_1 from "src_assets/m-1.png";
import packing_waiting_icon_2 from "src_assets/m-2.png";
import packing_waiting_icon_3 from "src_assets/basket_off.png";
import packing_waiting_icon_on_1 from "src_assets/m-1-on.png";
import packing_waiting_icon_on_2 from "src_assets/m-2-on.png";
import packing_waiting_icon_on_3 from "src_assets/basket_on.png";
import packing_main_img from "src_assets/packing-main-img.png";
import gifticon_main_img from "src_assets/gifticon-main-img.png";

import parcel_waiting_icon_1 from "src_assets/m-1.png";
import parcel_waiting_icon_2 from "src_assets/box2_off.png";
import parcel_waiting_icon_3 from "src_assets/parcel_off.png";
import parcel_waiting_icon_4 from "src_assets/box_off.png";
import parcel_waiting_icon_on_1 from "src_assets/m-1-on.png";
import parcel_waiting_icon_on_2 from "src_assets/box2_on.png";
import parcel_waiting_icon_on_3 from "src_assets/parcel_on.png";
import parcel_waiting_icon_on_4 from "src_assets/box_on.png";
import parcel_main_img from "src_assets/parcel-main-img.png";

import reservation_waiting_icon_1 from "src_assets/m-1.png";
import reservation_waiting_icon_2 from "src_assets/list_off.png";
import reservation_waiting_icon_on_1 from "src_assets/m-1-on.png";
import reservation_waiting_icon_on_2 from "src_assets/list2_on.png";
import reservation_main_img from "src_assets/reservation-main-img.png";



import "components/order/style/Orderwaiting.css";
import stateUtilityInstance from "TestClass";

const OrderWaiting = () => {
  const {state} = useLocation();
  const urlStr = window.location.href;
  const url = new URL(urlStr);
  const urlParams = url.searchParams;

  const od_option = urlParams.get('odOption');
  const od_task = urlParams.get('odTask');

  //let od_option = stateUtilityInstance.getPropertyByName('basketList');
  // console.log('basketList');
  // console.log(urlStr);
  // console.log(url);
  // console.log(urlParams);
  // console.log(od_task);
  // if(od_option != -1){
  //   stateUtilityInstance.setPropertyValue('basketList',[]);
  // }
  //alert(state.od_option);
  stateUtilityInstance.setMobileHeaderTitle('주문 상태');

  
  const navigate = useNavigate();
  const orderCancelClickEvent = () => {
    navigate("/mykiki/orderList",{replace:true});
  };
  const openGiftCouponList = () => {
    navigate("/mykiki/GiftCouponList",{replace:true});
  };
  const openCGVTicketList = () => {
    navigate("/mykiki/ticketList",{replace:true});
  };

  const deliveryPayResultView = () => {
    
    let mainText;

    switch(od_task){
      case '10':
        mainText = (<><b>조리중 </b>입니다.</>);
      break;
      case '11':
        mainText = (<><b>배송중 </b>입니다.</>);
        
      break;
      case '12':
        mainText = (<><b>배송완료 </b>되었습니다.</>);
      break;
      default :
      mainText = (<><b>결제완료 중 </b>입니다.</>);
      break;
    }
    
    return(
    <>
      <span className="order-frame-title order-title-bottom">배송</span>
      <div className="order-waiting-box">
        <ul className="progress-view">
          <li>
            <div className="progress-img-box">
              <img src={od_task === '00' || !od_task? delivery_waiting_icon_on : delivery_waiting_icon} alt="결제완료 이미지"></img>
            </div>
            <span>결제완료</span>
          </li>
          <span className="under-bar"></span>
          <li>
            <div className="progress-img-box">
              <img src={od_task === '10' ? delivery_waiting_icon_on_2 : delivery_waiting_icon_2} alt="조리중 이미지"></img>
            </div>
            <span>조리중</span>
          </li>
          <span className="under-bar"></span>
          <li>
            <div className="progress-img-box">
              <img src={od_task === '11' ? delivery_waiting_icon_on_3 : delivery_waiting_icon_3} alt="배송중 이미지"></img>
            </div>
            <span>배송중</span>
          </li>
          <span className="under-bar"></span>
          <li>
            <div className="progress-img-box">
              <img src={od_task === '12' ? delivery_waiting_icon_on_4 : delivery_waiting_icon_4} alt="배송완료 이미지"></img>
            </div>
            <span>배송완료</span>
          </li>
        </ul>
        <div className="progress-main">
          <div className="main-img">
          <img src={delivery_main_img}></img>
          </div>
          <span className="progress-note">
            {mainText}
          </span>
        </div>
        <button className="order-cancle" onClick={orderCancelClickEvent}>
          주문 내역
        </button>
      </div>
    </>
  )};

  const packingPayResultView = ()=> {
    
    let mainText;

    switch(od_task){
      case '20':
        mainText = (<><b>조리중 </b>입니다.</>);
      break;
      case '22':
        mainText = (<><b>배송중 </b>입니다.</>);
      break;
      default :
      mainText = (<><b>결제완료 중 </b>입니다.</>);
      break;
    }

    return(
    <>
      <span className="order-frame-title order-title-bottom">포장</span>
      <div className="order-waiting-box">
        <ul className="progress-view">
          <li>
            <div className="progress-img-box">
              <img src={od_task === '00' || !od_task? packing_waiting_icon_on_1 : packing_waiting_icon_1} alt="결제완료 이미지"></img>
            </div>
            <span>결제완료</span>
          </li>
          <span className="under-bar"></span>
          <li>
            <div className="progress-img-box">
              <img src={od_task === '20'? packing_waiting_icon_on_2 : packing_waiting_icon_2} alt="조리중 이미지"></img>
            </div>
            <span>조리중</span>
          </li>
          <span className="under-bar"></span>
          <li>
            <div className="progress-img-box">
              <img src={od_task === '22'? packing_waiting_icon_on_3 : packing_waiting_icon_3} alt="배송완료 이미지"></img>
            </div>
            <span>포장완료</span>
          </li>
        </ul>
        <div className="progress-main">
          <div className="main-img">
            <img src={packing_main_img}></img>
          </div>
          <span className="progress-note">
            {mainText}
          </span>
        </div>
        <button className="order-cancle" onClick={orderCancelClickEvent}>
          주문 내역
        </button>
      </div>
    </>
  )};

  const reservationPayResultView = () => {
    let mainText;

    switch(od_task){
      case '30':
        mainText = (<><b>예약접수가 완료</b>되었습니다.</>);
      break;
      default :
      mainText = (<><b>결제완료 중 </b>입니다.</>);
      break;
    }

    return(
    <>
      <span className="order-frame-title order-title-bottom">예약</span>
      <div className="order-waiting-box">
        <ul className="progress-view">
          <li>
            <div className="progress-img-box">
              <img src={od_task === '00' || !od_task? reservation_waiting_icon_on_1 : reservation_waiting_icon_1} alt="결제완료 이미지"></img>
            </div>
            <span>결제완료</span>
          </li>
          <span className="under-bar"></span>
          <li>
            <div className="progress-img-box">
              <img src={od_task === '30'? reservation_waiting_icon_on_2 : reservation_waiting_icon_2} alt="배송완료 이미지"></img>
            </div>
            <span>예약완료</span>
          </li>
        </ul>
        <div className="progress-main">
          <div className="main-img">
            <img src={reservation_main_img}></img>
          </div>
          <span className="progress-note">
            {mainText}
          </span>
        </div>
        <button className="order-cancle" onClick={orderCancelClickEvent}>
          주문 내역
        </button>
      </div>
    </>
  )};

  const parcelPayResultView = () => {
    let mainText;

    switch(od_task){
      case '40':
        mainText = (<><b>상품 준비중 </b>입니다.</>);
      break;
      case '41':
        mainText = (<><b>배송중 </b>입니다.</>);
      break;
      case '42':
        mainText = (<><b>택배 배송이 완료</b>되었습니다.</>);
      break;
      default :
      mainText = (<><b>결제완료 중 </b>입니다.</>);
      break;
    }
    return(
    <>
    <span className="order-frame-title order-title-bottom">택배</span>
      <div className="order-waiting-box">
        <ul className="progress-view">
          <li>
            <div className="progress-img-box">
              <img src={od_task === '00' || !od_task? parcel_waiting_icon_on_1 : parcel_waiting_icon_1} alt="결제완료 이미지"></img>
            </div>
            <span>결제완료</span>
          </li>
          <span className="under-bar"></span>
          <li>
            <div className="progress-img-box">
              <img src={od_task === '40'? parcel_waiting_icon_on_2 : parcel_waiting_icon_2} alt="조리중 이미지"></img>
            </div>
            <span>상품준비중</span>
          </li>
          <span className="under-bar"></span>
          <li>
            <div className="progress-img-box">
              <img src={od_task === '41'? parcel_waiting_icon_on_3 : parcel_waiting_icon_3} alt="배송중 이미지"></img>
            </div>
            <span>배송중</span>
          </li>
          <span className="under-bar"></span>
          <li>
            <div className="progress-img-box">
              <img src={od_task === '42'? parcel_waiting_icon_on_4 : parcel_waiting_icon_4} alt="배송완료 이미지"></img>
            </div>
            <span>배송완료</span>
          </li>
        </ul>
        <div className="progress-main">
          <div className="main-img">
            <img src={parcel_main_img}></img>
          </div>
          <span className="progress-note">
            {mainText}
          </span>
        </div>
        <button className="order-cancle" onClick={orderCancelClickEvent}>
          주문 내역
        </button>
      </div>
    </>
  )};

  const CGVPayResultView = () => {
    
    return(
    <>
    <span className="order-frame-title order-title-bottom">CGV</span>
      <div className="order-waiting-box">
      <ul className="progress-view"></ul>
        <div className="progress-main">
          <div className="main-img">
            <img src={gifticon_main_img}></img>
          </div>
          <span className="progress-note">
            <b>구매가 완료되었습니다.</b>
          </span>
        </div>
        <button className="order-cancle" onClick={orderCancelClickEvent}>
          주문 내역
        </button>
        <button className="order-cancle" onClick={openCGVTicketList}>
          CGV 티켓 리스트
        </button>
      </div>
    </>
  )};

  const gifticonPayResultView = () => {

    return(
    <>
    <span className="order-frame-title order-title-bottom">기프티콘</span>
      <div className="order-waiting-box">
      <ul className="progress-view"></ul>
        <div className="progress-main">
          <div className="main-img">
          <img src={gifticon_main_img}></img>
          </div>
          <span className="progress-note">
            <b>구매가 완료되었습니다.</b>
          </span>
        </div>
        <button className="order-cancle" onClick={orderCancelClickEvent}>
          주문 내역
        </button>
        <button className="order-cancle" onClick={openGiftCouponList}>
          기프티콘 리스트
        </button>
      </div>
    </>
  )};



  const DeliveryPayResultView = ({od_option}) => {
    
    let option = parseInt(od_option);
    switch(option){
      case 1: //포장
        return(packingPayResultView());
      case 2: //배송
        return(deliveryPayResultView());
      case 3: //매장식자 (미사용)
        return(deliveryPayResultView());
      case 4: //예약
        return(reservationPayResultView());
      case 5: //택배
        return(parcelPayResultView());
      case 6: //CGV
        return(CGVPayResultView());
      case 7: //기프티콘
        return(gifticonPayResultView());
      default :
        return (deliveryPayResultView());
    }
    
  }


  return (
    <section className="order-waiting-frame">
      <DeliveryPayResultView od_option={od_option} />
    </section>
  );
};

export default OrderWaiting;
