import { useLocation, useNavigate } from "react-router-dom";

import FollowBasket from "components/order/FollowBasket";

import "components/order/style/product/ProductDetail.css";

import testimg from "src_assets/cosmetics/main_cosmetics_img.jpg";
import aa from "src_assets/cosmetics/main_cosmetics_img.jpg";
import bb from "src_assets/cosmetics/sub_cosmetics_img_1.jpg";
import cc from "src_assets/cosmetics/sub_cosmetics_img_2.jpg";
import shop from "src_assets/cosmetics/cart-shopping-solid.png";
import detail from "src_assets/cosmetics/detail.png";
import pre from "src_assets/allow-button-left.png";
import ne from "src_assets/allow-button-right.png";

import slide1 from "src_assets/cosmetics/slide_cosmetics_1.jpg";
import slide2 from "src_assets/cosmetics/slide_cosmetics_2.jpg";
import slide3 from "src_assets/cosmetics/slide_cosmetics_3.jpg";
import slide4 from "src_assets/cosmetics/slide_cosmetics_4.jpg";
import detail_info from "src_assets/cosmetics/detail_info.jpg";

import COLOR1 from "src_assets/cosmetics/COLOR-1.png";
import COLOR2 from "src_assets/cosmetics/COLOR-2.png";
import COLOR3 from "src_assets/cosmetics/COLOR-3.png";
import COLOR4 from "src_assets/cosmetics/COLOR-4.png";
import COLOR5 from "src_assets/cosmetics/COLOR-5.png";
import { useEffect } from "react";
import { useState } from "react";
import {
  getMenuDetail,
  getProdMenu,
  setBasket,
  delBasketAll,
  checkGiftPurchase,
} from "service/OrderService";
import { serverErrorHandler, SHOP_CUSTOM_IMG_PATH } from "App-config";
import stateUtilityInstance from "../../../TestClass";
import { isKiikeyApp, IS_IOS, IS_ANDROID } from "App-config";

const ProductDetail = ({ basketCount, basketReload }) => {
  const navigate = useNavigate();

  const { state } = useLocation();

  const [tabCount, setTabCount] = useState(1);

  const [menu, setMenu] = useState(null);
  const [menuDetail, setMenuDetail] = useState(null);
  const [subMenu, setSubMenu] = useState(null);

  useEffect(() => {
    if (state && state.shopInfo) {
      getProdMenu({
        sIdx: state.shopInfo.s_idx,
        mcIdx: state.shopInfo.mc_idx,
        mIdx: state.shopInfo.m_idx,
      })
        .then((res) => {
          if (res.success && res.data.rows.length > 0) {
            console.log(res.data);
            console.log(state.shopInfo);
            setMenu(res.data.rows[0]);
            setSubMenu(res.data.subMenus[0]);
            getMenuDetail({
              s_idx: state.shopInfo.s_idx,
              m_idx: state.shopInfo.m_idx,
              table_name: res.data.rows[0].table_name,
            })
              .then((md) => {
                setMenuDetail(md.data);
              })
              .catch((mderr) => {
                console.log("error");
                console.error(mderr);
                serverErrorHandler(mderr);
              });
          }
        })
        .catch((err) => {
          console.log("error2");
          console.error(err);
          serverErrorHandler(err);
        });
    } else {
      navigate(-1);
    }
  }, [state]);

  const basketDeleteAll = () => {
    console.log("삭제 시작");
    return delBasketAll()
      .then((res) => {
        console.log(res);
        console.log("삭제 성공");
        return true;

        //setBasketList(newBasketList);
      })
      .catch((err) => {
        console.log("삭제 실패");
        console.error(err);
        return false;
      });
  };

  const basketAddClickEvent = async () => {
    // 매장영업중인지 체크

    console.log(basketCount);
    // id가 countNumber인 p태그의 텍스트를 가져와서 숫자로 변환
    const countNumber = parseInt(document.getElementById("countNumber").innerText);
    if (basketCount > 0) {
    //  const a = await basketDeleteAll();

      //if (await basketDeleteAll()) {
        const menutemp = menu.mc_menus[0];
        const tabName = menu.table_name;
        const m_sIdx = menu.s_idx;
        const reqTemp = [
          {
            m_count: countNumber,
            m_idx: menutemp.m_idx,
            m_name: menutemp.m_name,
            m_price: menutemp.m_price * 1,
            optionCateArray: [],
            scheduleTime: undefined,
            totalPrice: 0,
            table_name: tabName,
          },
        ];

        setBasket({ sIdx: m_sIdx, rows: reqTemp })
          .then((res) => {
            basketReload();
            navigate("/order/confirm");
          })
          .catch((err) => {
            console.error(err);

            serverErrorHandler(err);
          });
     // } else {
     //   serverErrorHandler();
     // }
    } else {
      const menutemp = menu.mc_menus[0];
      const tabName = menu.table_name;
      const m_sIdx = menu.s_idx;
      const reqTemp = [
        {
          m_count: countNumber,
          m_idx: menutemp.m_idx,
          m_name: menutemp.m_name,
          m_price: menutemp.m_price * 1,
          optionCateArray: [],
          scheduleTime: undefined,
          totalPrice: 0,
          table_name: tabName,
        },
      ];

      setBasket({ sIdx: m_sIdx, rows: reqTemp })
        .then((res) => {
          basketReload();
          navigate("/order/confirm");
        })
        .catch((err) => {
          console.error(err);
          serverErrorHandler(err);
        });
    }
  };

  const basketClickEvent = () => {
    const menutemp = menu.mc_menus[0];

    if (state.shopInfo.c2_idx == "21") {
      //기프티콘 임시 차단
      window.alert("준비중입니다.");
      return;

      checkGiftPurchase()
        .then((res) => {
          if (res.success) {
            // const tabName = menu.table_name;
            // const m_sIdx = menu.s_idx;

            // const reqTemp = [
            //   {
            //     m_count: 1,
            //     m_idx: menutemp.m_idx,
            //     m_name: menutemp.m_name,
            //     m_price: menutemp.m_price * 1,
            //     optionCateArray: [],
            //     scheduleTime: undefined,
            //     totalPrice: 0,
            //     table_name: tabName,
            //   },
            // ];
            if (
              window.confirm(
                menutemp.m_name +
                  "를(을) 장바구니에 담겠읍니까? \n"
              )
            ) {
              basketAddClickEvent();
            }
          }
        })
        .catch((err) => {
          window.alert("금일 기프티콘 구매한도를 초과 하셧습니다.");
        });
    } else {
      if (
        window.confirm(
          menutemp.m_name +
            "를(을) 장바구니에 담겠읍니까?? \n"
        )
      ) {
        basketAddClickEvent();
      }
    }
  };
  const minusClickEvent = (e) => {
    const target = e.target.nextElementSibling;
    let val = parseInt(target.innerText);
    if (isNaN(val)) val = 0;
    target.innerText = val > 0 ? val - 1 : val;
  };
  
  const plusClickEvent = (e) => {
    const target = e.target.previousElementSibling;
    let val = parseInt(target.innerText);
    if (isNaN(val)) val = 0;
    target.innerText = val + 1;
  };
  return (
    <section className="shop-menu-list-frame">
      {menu && (
        <>
          <div className="shop-menu-list-inner">
            {/* <div className="shop_name_area"> */}
            {/* {<div className="pre_btn">
                <img src={pre} alt="뒤로가기"></img>
              </div>} */}
            {/* <div className="shop_name">
                <h2>{menu.mc_menus[0].m_name}</h2>
              </div>
            </div> */}
            <div className="product_main_area">
              <div className="img_slide_area">
                <div className="Slide_img">
                  <img
                    src={
                      SHOP_CUSTOM_IMG_PATH +
                      menu.table_name.replace("_", "") +
                      "/menu/" +
                      menu.mc_menus[0].m_img
                    }
                    alt="화장품 사진"
                  ></img>
                </div>
              </div>
              <div className="product_name">
                <h2>{menu.mc_menus[0].m_name}</h2>
                {/*<p>
                  {menu.mc_name} {menu.mc_menus[0].m_detail}
                  </p>*/}
              </div>

              {menu.mc_menus[0].m_option_cate.length > 0 && (
                <> 
                </>
              )}

              <div className="product_main_info">
                <div className="price">
                  <h2>
                    {menu.mc_menus[0].m_price
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    원
                  </h2>
                </div>
                <div  style={{ textAlign: 'right' }}>
                  구매갯수
                </div>
                <div className="item-count-box">
                  <div className="count">
                    <button className="count-btn" onClick={minusClickEvent}>
                      -
                    </button>
                    <p id ="countNumber"className="count-num">1</p>
                    <button className="count-btn" onClick={plusClickEvent}>
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab_menu_btn_Area">
              <div
                className={
                  state.shopInfo?.c2_idx == 21
                    ? "tabmanu_btn_gift"
                    : "tabmanu_btn " + (tabCount == 1 ? "bold" : "")
                }
                onClick={() => setTabCount(1)}
              >
                상세정보
              </div>
              {state.shopInfo?.c2_idx != 21 && (
                <>
                  <div
                    className={"tabmanu_btn " + (tabCount == 2 ? "bold" : "")}
                    onClick={() => setTabCount(2)}
                  >
                    리뷰
                  </div>
                  <div
                    className={"tabmanu_btn " + (tabCount == 3 ? "bold" : "")}
                    onClick={() => setTabCount(3)}
                  >
                    문의
                  </div>
                </>
              )}
            </div>

            <div className="tabmenu display_none">
              <img src={detail} alt="상세페이지closed"></img>
              <div className="detail_btn">
                <div className="more_info">상세 정보 펼쳐보기</div>
              </div>
            </div>

            {/* <div className="tabmenu2">
              <img src={detail_info} alt="상세페이지open "></img>
            </div> */}
            {tabCount == 1 && (
              <div className="tabmenu2">
                <br></br>
                <pre className="tabmenu2-pre">
                  {menu.mc_menus[0].m_explained}
                </pre>
                {menuDetail &&
                  menuDetail.imgs.length > 0 &&
                  menuDetail.imgs.map((img, imgIndex) => {
                    return (
                      <img
                        key={imgIndex}
                        src={
                          SHOP_CUSTOM_IMG_PATH +
                          menu.table_name.replace("_", "") +
                          "/" +
                          img.s_idx +
                          "/" +
                          img.m_idx +
                          "/" +
                          img.img_name
                        }
                        alt="상세페이지open "
                      ></img>
                    );
                  })}
              </div>
            )}

            {subMenu && Object.keys(subMenu).length > 0 && (
              <>
                <div className="recommended_area_Title">
                  <p>함께 보면 좋은 상품</p>
                </div>
                <div className="recommend_area">
                  {subMenu.mc_menus.map((sMenu) => {
                    return (
                      <div
                        className="recommend_product_area"
                        onClick={() => {
                          window.scrollTo(0, 0);
                          navigate("/order/prod", {
                            state: {
                              shopInfo: {
                                s_idx: state.shopInfo.s_idx,
                                mc_idx: subMenu.mc_idx,
                                m_idx: sMenu.m_idx,
                              },
                            },
                          });
                        }}
                      >
                        <div className="Recommend_product">
                          <img
                            src={
                              SHOP_CUSTOM_IMG_PATH +
                              subMenu.table_name.replace("_", "") +
                              "/menu/" +
                              sMenu.m_img
                            }
                            alt="화장품 사진"
                          ></img>
                        </div>
                        <div className="recommend_name">
                          <p>{sMenu.m_name}</p>
                        </div>
                        <div className="recommend_price">
                          {/* <p>이쁨 할인가</p>
                      <span>5,000원</span> */}
                          <h3>
                            {sMenu.m_price
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            원
                          </h3>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}

            <div className="Buy_btn">
              {/* <div className="shopping_cart_btn" onClick={basketClickEvent}>장바구니</div> */}
              <div className="Buying_btn" onClick={basketClickEvent}>
                장바구니 담기
              </div>
            </div>
          </div>
          {/* <FollowBasket
            menuList={[]}
            basketList={{ rows: [] }}
            basketCount={basketCount}
            basketClickEvent={basketClickEvent}
          /> */}
        </>
      )}
    </section>
  );
};

export default ProductDetail;
