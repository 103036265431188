import { useState, useEffect, useRef } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { debounceFunction } from "App-config";

import { getMemberInfo } from "service/OrderService";

import OrderUsePay from "components/order/payment/OrderUsePay";
import "components/order/style/OrderHistory.css";
import "components/order/style/OrderDetail.css";
import { async } from "@firebase/util";

const ShopComponent = ({
  payChangeHandler,
  childComponentRef,
  handleChange,
  checkRadio,
  location,
}) => {
  const DelivType = ({ handleChange, checkRadio }) => {
    return (
      <div className="order-way-select">
        <>
          <input
            type="radio"
            name={"order-way-radio0"}
            id={location.state.is_private ? "barcode-way0" : "barcode-way1"}
            value={location.state.is_private ? "8" : "9"}
            onChange={(e) => {
              handleChange(e, e.currentTarget.checked, 0);
            }}
            checked={true}
          />
          <label
            htmlFor={
              location.state.is_private ? "barcode-way0" : "barcode-way1"
            }
          />
        </>
      </div>
    );
  };

  return (
    <div className="order-way-box" key={"10363"}>
      <div className="shop-folder">
        <div className="shop-name">
          <span className="order-title">
            {location.state.is_private == true ? "포인트 충전" : "바코드 생성"}
          </span>
        </div>
      </div>
      <div className="order-list-house">
        <div className="order-list-wrap" data-sidx={"10363"}>
          <DelivType handleChange={handleChange} checkRadio={checkRadio} />
          <OrderUsePay
            payChangeHandler={payChangeHandler}
            ref={childComponentRef}
          />
        </div>
        <div className="bottomline"></div>
      </div>
    </div>
  );
};

const InputOrderConfirm = ({ basketReload, userInfo, setUserInfo }) => {
  const navigate = useNavigate();

  const [checkedInputs, setCheckedInputs] = useState({});
  const [checkRadio, setCheckRadio] = useState({});
  const [cAmt, setcAmt] = useState(0);
  const childComponentRef = useRef();
  const location = useLocation();
  // .toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

  const handleChange = (e, checked, cnt) => {
    let id = e.target.id;
    setCheckRadio({ ...checkRadio, [`${cnt}`]: id });
  };

  useEffect(() => {
    let checkedItems = {};
    let radioObj = {};
    let totalAmt = 0;

    radioObj[0] = "barcode-way0";

    setCheckedInputs(checkedItems);
    setcAmt(totalAmt);
    setCheckRadio(radioObj);

    let ele = document.querySelector("#root");

    ele.style.height = window.innerHeight + "px";
    console.log(window.innerHeight);

    return () => {
      ele.style.height = "auto";
    };
  }, []);

  const PointChargeGuide = () => {
    return (
      <>
        <p>포인트 지급 후 환불은 불가능 합니다.</p>
      </>
    );
  };

  const payChangeHandler = (value) => {
    setcAmt(value * 1);
  };

  const paymentClickHandler = async () => {
    const odOption = document.querySelector(
      '[name^="order-way-radio"]:checked'
    )?.value;

    const sIdx = "10363";

    if (cAmt < 1) {
      alert("정확한 금액을 입력해주세요.");
      return;
    }

    if (cAmt < 100) {
      alert("최소 결제금액은 100원입니다.");
      return;
    }

    navigate("/order/inputPayment", {
      state: {
        odOption: odOption,
        sIdx: sIdx,
        cAmt: cAmt,
        usePoint: location.state.usePoint,
      },
    });
  };

  return (
    <section className="order-history-frame">
      <span className="order-frame-title oft-btm">장바구니</span>

      {
        <ShopComponent
          payChangeHandler={payChangeHandler}
          childComponentRef={childComponentRef}
          handleChange={handleChange}
          checkRadio={checkRadio}
          location={location}
        />
      }

      <div className="final-confirmation">
        <div className="payment-result">
          <span className="total-payment-result">
            <p>총 결제금액</p>
            <p className="tAmt">
              {cAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
            </p>
          </span>
        </div>
        <div className="kiikey-guide" style={{ height: window.innerHeight }}>
          <p>이쁨은 통신판매 중개자이며 통신판매의 당사자가 아닙니다.</p>
          <p>따라서 이쁨은 상품거래 정보 및 거래에 책임을 지지 않습니다.</p>
          {<PointChargeGuide />}
        </div>
        <button
          className="payment-button"
          style={{ width: "100%", position: "absolute", bottom: 0 }}
          onClick={paymentClickHandler}
        >
          <span className="tAmt">
            {cAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
          </span>{" "}
          결제하기
        </button>
      </div>
    </section>
  );
};

export default InputOrderConfirm;
