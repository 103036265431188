import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getOrderDetail } from "service/OrderService";

import "components/order/style/Statement.css";

const Statement = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [orderDetail, setOrderDetail] = useState(null);

  // 지호 220708 주문상세
  useEffect(() => {
    if (params.odIdx) {
      getOrderDetail({ od_idx: params.odIdx })
        .then((res) => {
          if (!!res.data) {
            setOrderDetail(res.data);
          }
        })
        .catch((err) => {
          console.error(err);
          navigate("/mykiki/orderList");
        });
    }
  }, []);

  const OrderState = ({ order_state }) => {
    switch (order_state) {
      case "0":
        return (
          <span className="order-frame-title order-title-bottom">결제대기</span>
        );
      case "1":
        return (
          <span className="order-frame-title order-title-bottom">결제완료</span>
        );
      case "2":
        return (
          <span className="order-frame-title order-title-bottom">배송준비</span>
        );
      case "3":
        return (
          <span className="order-frame-title order-title-bottom">배송시작</span>
        );
      case "4":
        return (
          <span className="order-frame-title order-title-bottom">배송완료</span>
        );
      default:
        return (
          <span className="order-frame-title order-title-bottom">취소</span>
        );
    }
  };

  const OrderOption = ({ order_option }) => {
    switch (order_option) {
      case "1":
        return <p>포장</p>;
      case "3":
        return <p>방문</p>;
      case "4":
        return <p>예약</p>;
      case "5":
        return <p>택배</p>;
      case "6":
      case "7":
      case "8":
        return <p>구매</p>;
      default:
        return <p>배송</p>;
    }
  };

  const OrderPayType = ({ pay_type }) => {
    switch (pay_type) {
      case "1":
        return <p>만나서 현금결제</p>;
      case "2":
        return <p>만나서 카드결제</p>;
      case "3":
        return <p>전액 포인트결제</p>;
      case "11":
        return <p>카드결제</p>;
      default:
        return <p>휴대폰결제</p>;
    }
  };

  const MenuOption = ({ menuList }) => {
    return menuList.map((menu) => {
      const optionList = menu.options.map((option) => {
        if (option.moc_name) {
          return <span className="list-option-name">{option.moc_name}</span>;
        } else {
          return (
            <li>
              <span className="statement-body-name">
                <p>ㄴ{option.mo_name}</p>
              </span>
              <span className="statement-body-detail">
                {/*만약 값이 없다면 p 안의 내용은 빈칸 */}
                <p>{option.c_cnt}</p>
                <p>
                  {option.mo_price
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " 원"}
                </p>
              </span>
            </li>
          );
        }
      });
      return (
        <li>
          <div className="statement-list-head">
            <span className="statement-head-name">
              <p>{menu.m_name}</p>
            </span>
            <span className="statement-head-detail">
              {/*만약 값이 없다면 p 안의 내용은 빈칸 */}
              <p>{menu.c_cnt}</p>
              <p>
                {menu.m_price
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " 원"}
              </p>
            </span>
          </div>
          <ul className="statement-list-body">{optionList}</ul>
        </li>
      );
    });
  };

  const OrderMenu = ({ order_menu }) => {
    // order.rows 반복

    let newArr = [];
    let currentIndex = 0;
    order_menu.forEach((menu, index) => {
      if (menu.m_name) {
        if (index > 0) {
          currentIndex++;
        }
        newArr.push({
          m_name: menu.m_name,
          m_price: menu.m_price * 1,
          c_cnt: menu.c_cnt,
          options: [],
        });
      } else if (menu.moc_name) {
       // newArr[currentIndex].options.push({
       //   c_cnt: menu.c_cnt,
       //   moc_name: menu.moc_name,
       // });
      } else if (menu.mo_name) {
       // newArr[currentIndex].m_price =
        //  newArr[currentIndex].m_price + menu.mo_price * 1;
       // newArr[currentIndex].options.push({
       //   c_cnt: menu.c_cnt,
         // mo_name: menu.mo_name,
         // mo_price: menu.mo_price * 1,
        //});
      }
    });

    return (
      <ul className="statement-list-area">
        <MenuOption menuList={newArr} />
      </ul>
    );

    // const menus = order_menu.map((menu) => {
    //   return <MenuOption menu={menu} />;
    // });
    // return menus;
  };

  function orderHistory() {
    navigate(-1);
  }

  return (
    orderDetail && (
      <section className="statement-frame">
        {/* cgc지호 */}
        {orderDetail.order.od_option < 6 && (
          <OrderState order_state={orderDetail.order.od_state} />
        )}
        <div className="statement-paper">
          <div className="statement-close">
            <button className="close-button" onClick={orderHistory}>
              X
            </button>
          </div>

          <div className="statement-title-box">
            <span className="statement-title">
              
                      주문내역서
                      
                      {orderDetail.order.od_state == -1 ? (
                      <p>취소</p>
                      ) : null}
                     
                    </span>
                    <span className="statement-shop-title">
                      
              <p align="left">
            {orderDetail.order.od_cancel_type == 1 && ("취소사유:단순변심")}
            {orderDetail.order.od_cancel_type == 2 && ("취소사유:재고없음")}
            {orderDetail.order.od_cancel_type == 3 && ("취소사유:배송지연")}
            {orderDetail.order.od_cancel_type == 4 && ("취소사유:기타")}
            </p>
              <p
                onClick={() => {
                  if (orderDetail.order.od_option == 7) {
                    navigate("/mykiki/GiftCouponList"); 
                  } else if (orderDetail.order.od_option == 8){
                    navigate("/mykiki/barcodeUseList");
                  } else {
                      navigate("/order/shop", {
                        state: { shopInfo: orderDetail.order },
                      });
                  }
                }}
              >
                {orderDetail.od_option != 7 ? orderDetail.order.s_name : "기프티콘 리스트"}
              </p>
            </span>
          </div>
          {orderDetail.order.od_option < 6 && (
            <>
              <div className="delivery-address">
                <span className="address-head">
                  <p>배송지주소 :</p>
                  <p>{orderDetail.order.od_addr_old}</p>
                </span>
                <span className="address-road-number">
                  <p>(도로명){orderDetail.order.od_addr}</p>
                </span>
              </div>
              <div className="statement-message">
                
               
              </div>
            </>
          )}
          <div className="statement-list-title">
            <span className="statement-menu-name">
              <p>상품명</p>
            </span>
            <span className="statement-detail">
              <p>수량</p>
              <p>금액</p>
            </span>
          </div>

          <OrderMenu order_menu={orderDetail.rows} />

          <div className="statement-list-result">
            <span>
              <p>결제금액 :</p>
              <p>
                {"+" +
                  (
                    orderDetail.order.sp_product_amt -
                    orderDetail.order.od_delivery_tip
                  )
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                  " 원"}
              </p>
              
            </span>
            {orderDetail.order.od_option < 6 && (
              <span>
                <p>배송비</p>
                <p>
                  {"+" +
                    orderDetail.order.od_delivery_tip
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                    " 원"}
                </p>
              </span>
            )}
            <span>
              <p>포인트 사용 :</p>
              <p>
                {orderDetail.order.od_use_point
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " 원"}
              </p>
            </span>
          </div>
          <div className="statement-payment-check">
            <span>
              <p>결제방법</p>
              {orderDetail.order.sp_product_amt - orderDetail.order.od_use_point > 0 ? (
                  "계좌이체"
                ) : (
                  <OrderPayType pay_type={orderDetail.order.sp_pay_type} />
                )}
            </span>
            <span>
              <p>총 결제 금액</p>
              <p>
                {(
                  orderDetail.order.sp_product_amt -
                  orderDetail.order.od_use_point
                )
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " 원"}
              </p>
            </span>
          </div>
          <div className="statement-info">
            <p>주문시각 {orderDetail.order.od_datetime}</p>
            <p>주문번호 : {orderDetail.order.od_idx}</p>
            <p>상점명 : {orderDetail.order.s_name}</p>
            <p>전화번호 : {orderDetail.order.s_biz_tel}</p>
            {/* <p>안심번호는 주문 접수 후 최대 3시간 동안 유효합니다.</p> */}
          </div>
        </div>
        <div id="countinfo" >
          <p className="Precautions">
          { <span className="font-weight"> 입금계좌:우리은행 1005-503-785077 백옥생코리아(주)</span>
                }
          </p>
          <p className="Precautions">
          { <span className="font-weight"> 반드시 주문자 이름으로 입금부탁드립니다</span>
                 }
          </p>
          <p className="Precautions">
          { <span className="font-weight"> 입금 확인이 된 이후 출고처리가 진행됩니다</span>
                 }
          </p>
          <p className="Precautions">
          { <span className="font-weight"> 포인트 사용 후 총 결제금액을 입금해 주세요</span>
                 }
          </p>
          </div>
      </section>
    )
  );
};

export default Statement;
