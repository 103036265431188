import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  delBasket,
  getBasket,
  setBuyBasket,
  getMemberInfo,
} from "service/OrderService";

import "components/order/style/OrderHistory.css";
import "components/order/style/OrderDetail.css";
import "components/order/style/ShopMenuFood.css";
import { async } from "@firebase/util";

const OrderConfirm = ({ basketReload, userInfo, setUserInfo }) => {
  const navigate = useNavigate();

  let deleteShopPrice = 0;
  const [checkedInputs, setCheckedInputs] = useState({});
  const [checkRadio, setCheckRadio] = useState({});
  const [cAmt, setcAmt] = useState(0);
  // .toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  
  const changeHandler = (checked, id, e, status, menuCnt) => {
    let target;

    if (status) {
      target = e.target
        .closest(".order-way-box")
        .querySelectorAll(".order-content-list")
        [menuCnt].querySelectorAll(".menu-price p");
    } else {
      target = e.target
        .closest(".order-menu-name-box")
        .querySelectorAll(".menu-price p");
    }
    // if(!target[0]){
    //   navigate(-2);
    // }

    let price = target[0]?.innerText.replace("원", "").replaceAll(",", "");
    let cnt = target[1]?.innerText.replace("개", "").replaceAll(",", "");

    setCheckedInputs({
      ...checkedInputs,
      [`${id}`]: checked,
    });

    if (status) {
      deleteShopPrice = price * 1;
      console.log('del:'+deleteShopPrice);
      console.log('price:'+price);
      console.log('cnt:'+cnt);
      setcAmt(cAmt * 1 - deleteShopPrice);
    } else {
      console.log('price:'+price);
      console.log('cnt:'+cnt);
      setcAmt(checked ? cAmt * 1 + price * 1  : cAmt * 1 - price * 1 );
    }
  };

  const handleChange = (e, checked, cnt) => {
    let id = e.target.id;
    setCheckRadio({ ...checkRadio, [`${cnt}`]: id });
  };
  const minusClickEvent = (e) => {
    const target = e.target.nextElementSibling;
    let val = parseInt(target.innerText);
    if (isNaN(val)) val = 0;
    target.innerText = val > 0 ? val - 1 : val;
    const prePrice = e.target.previousElementSibling;   
    prePrice.innerText =  parseInt(target.dataset.price)*parseInt(target.innerText);
    prePrice.innerText = prePrice.innerText.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    
    console.log(prePrice.innerText);
    
      const checked = e.target.parentElement.previousElementSibling.querySelector('[name^="order-menu-checkbox"]').checked;
      if(checked){      
    const amt = document.querySelectorAll(".tAmt");
    amt[0].innerText =  parseInt(amt[0].innerText.replace("원", "").replaceAll(",", "")) - parseInt(target.dataset.price);
    amt[0].innerText = amt[0].innerText.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");    
    amt[1].innerText = amt[0].innerText.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //setcAmt(cAmt * 1 -  parseInt(target.dataset.price));
      }

  };
  
  const plusClickEvent = (e, maxCnt) => {
    const target = e.target.previousElementSibling;
    console.log(target);  
    let val = parseInt(target.innerText);
    if (isNaN(val)) val = 0;
    if (!!maxCnt) {
      if (val >= maxCnt) {
        console.log("최대 옵션선택 초과");
        return false;
      }
    }
    target.innerText = val + 1;
    const prePrice = e.target.previousElementSibling.previousElementSibling.previousElementSibling;
    prePrice.innerText =  parseInt(target.dataset.price)*parseInt(target.innerText);
    console.log(target.dataset.price);
    console.log(target.innerText);
    prePrice.innerText = prePrice.innerText.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    console.log(e.target.previousElementSibling.previousElementSibling.previousElementSibling);
    console.log(e.target.parentElement.previousElementSibling);
    const checked = e.target.parentElement.previousElementSibling.querySelector('[name^="order-menu-checkbox"]').checked;
    console.log( e.target.parentElement.previousElementSibling.querySelector('[name^="order-menu-checkbox"]'));
    if(checked){
    const amt = document.querySelectorAll(".tAmt");
    amt[0].innerText =  parseInt(amt[0].innerText.replace("원", "").replaceAll(",", "")) + parseInt(target.dataset.price);
    amt[1].innerText = amt[0].innerText.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
    amt[1].innerText = amt[0].innerText.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //  setcAmt(cAmt * 1 +  parseInt(target.dataset.price));
    }
  };
  const [basketList, setBasketList] = useState(null);
  const [basketStatus, setBasketStatus] = useState(false);

  useEffect(() => {
    getBasket()
      .then((res) => {
        if (res.success) {
          if (res.data.length < 1) {
            alert("장바구니가 비어있습니다.11");
           // navigate(-1);
          } else {
            console.dir(res.data);
            setBasketList(res.data);
            setBasketStatus(true);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    let checkedItems = {};
    let radioObj = {};
    let totalAmt = 0;
    if (basketStatus) {
      let basketItems = basketList;
      for (let i = 0; i < basketItems.length; i++) {
        let basketItem = basketItems[i];
        basketItem.rows.forEach((item) => {
          checkedItems[`${item.bm_idx}`] = true;
          totalAmt += item.totalPrice * 1;
        });
        radioObj[`${i}`] =
          basketItem.s_is_delivery * 1 == 1
            ? "delivery-way" + i
            : basketItem.s_is_packing * 1 == 1
            ? "wrap-way" + i
            : basketItem.s_is_parcel * 1 == 1
            ? "parcel-way" + i
            : basketItem.c2_idx * 1 == 20
            ? "ticket-way" + i
            : basketItem.c2_idx * 1 == 21
            ? "gift-way" + i
            : "";
      }
      setCheckedInputs(checkedItems);
      setcAmt(totalAmt);
      setCheckRadio(radioObj);
    }
  }, [basketStatus]);

  const basketDeleteHandler = (e, arg, flag) => {
    let rqArg = {};
    if (flag) {
      rqArg.sIdx = arg;
    } else {
      rqArg.bmIdx = arg;
    }
    delBasket(rqArg)
      .then((res) => {
        let targetSidx = 0;
        let afterLength = 0;

        let newBasketList = [...basketList];
        if (flag) {
          // 샵 전체 지우기
          for (let i = 0; i < newBasketList.length; i++) {
            const basketShop = newBasketList[i];
            if (basketShop.s_idx == arg) {
              newBasketList.splice(i, 1);
              targetSidx = basketShop.s_idx;
              afterLength = newBasketList.length;
              break;
            }
          }
          if (!afterLength) {
            navigate(-1);
          }
          // 샵의 메뉴가격 초기화
          const checkTargetList = e.target
            .closest(".order-way-box")
            .querySelectorAll('[name^="order-menu-checkbox"]:checked');
          let cnt = 0;
          checkTargetList.forEach((checkTarget) => {
            checkTarget.checked = false;
            changeHandler(
              checkTarget.checked,
              checkTarget.dataset.bmidx,
              e,
              true,
              cnt
            );
            cnt++;
          });
        } else {
          // 메뉴를 장바구니에서 지우기
          for (let i = 0; i < newBasketList.length; i++) {
            const basketShop = newBasketList[i];
            for (let j = 0; j < basketShop.rows.length; j++) {
              const rows = basketShop.rows[j];
              if (rows.bm_idx == arg) {
                newBasketList[i].rows.splice(j, 1);
                targetSidx = basketShop.s_idx;
                afterLength = newBasketList[i].rows.length;
                break;
              }
            }
          }
          // 메뉴를 지운 후 해당 매장의 메뉴가 없으면 매장전체 삭제
          if (afterLength < 1) {
            for (let i = 0; i < newBasketList.length; i++) {
              const basketShop = newBasketList[i];
              if (basketShop.s_idx == targetSidx) {
                newBasketList.splice(i, 1);
                break;
              }
            }
            if (newBasketList.length < 1) {
              navigate(-1);
            }
          }
          // 메뉴가격 초기화
          const checkTarget = e.target
            .closest(".order-menu-name-box")
            .querySelector('[name^="order-menu-checkbox"]:checked');
          if (checkTarget) {
            checkTarget.checked = false;
            changeHandler(checkTarget.checked, checkTarget.id, e);
          }
        }
        //
        
        setBasketList(newBasketList);
      })
      .catch((err) => {
        navigate(-1);
        console.error(err);
      });
    basketReload();
  };

  // 상점마다 접는기능
  const folderClickEvent = (e) => {
    e.target.parentElement.nextSibling.classList.toggle("folder");
  };

  // // 메뉴체크
  // const menuOptionCheckHandler = (e, menu) => {
  //   const tgList = document.querySelectorAll(".tAmt");
  //   const cAmt = tgList[0].innerText.replace("원", "").replaceAll(",", "");
  //   if (e.target.checked) {
  //     tgList.forEach((ele) => {
  //       ele.innerText =
  //         (cAmt * 1 + menu.totalPrice)
  //           .toString()
  //           .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원";
  //     });
  //   } else {
  //     tgList.forEach((ele) => {
  //       ele.innerText =
  //         (cAmt * 1 - menu.totalPrice)
  //           .toString()
  //           .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원";
  //     });
  //     const radioTarget = e.target
  //       .closest(".order-list-wrap")
  //       .querySelector('[name^="order-way-radio"]:checked');
  //     if (radioTarget) {
  //       radioTarget.checked = false;
  //     }
  //   }
  // };

  const DelivType = ({ shop, cnt }) => {
    return (
      
      <div className="order-way-select">
        {shop.used_schedule > 0 ? (
          <>
            <input
              type="radio"
              name={"order-way-radio" + cnt}
              id={"schedule-way" + cnt}
              value="4"
              onChange={(e) => {
                handleChange(e, e.currentTarget.checked, cnt);
              }}
              checked={checkRadio[cnt] == "schedule-way" + cnt ? true : false}
            />
            <label htmlFor={"schedule-way" + cnt} />
          </>
        ) : (
          <>
            {shop.s_idx == 10363 && ( // 기프티콘 샵 s_idx
              <>
                <input
                  type="radio"
                  name={"order-way-radio" + cnt}
                  id={"gift-way" + cnt}
                  value="7"
                  onChange={(e) => {
                    handleChange(e, e.currentTarget.checked, cnt);
                  }}
                  checked={checkRadio[cnt] == "gift-way" + cnt ? true : false}
                />
                <label htmlFor={"gift-way" + cnt} />
              </>
            )}
            {shop.s_idx == 10228 && (
              <>
                <input
                  type="radio"
                  name={"order-way-radio" + cnt}
                  id={"ticket-way" + cnt}
                  value="6"
                  onChange={(e) => {
                    handleChange(e, e.currentTarget.checked, cnt);
                  }}
                  checked={checkRadio[cnt] == "ticket-way" + cnt ? true : false}
                />
                <label htmlFor={"ticket-way" + cnt} />
              </>
            )}
            {shop.s_is_delivery > 0 && (
              <>
                <input
                  type="radio"
                  name={"order-way-radio" + cnt}
                  id={"delivery-way" + cnt}
                  value="2"
                  onChange={(e) => {
                    handleChange(e, e.currentTarget.checked, cnt);
                  }}
                  checked={
                    checkRadio[cnt] == "delivery-way" + cnt ? true : false
                  }
                />
                <label htmlFor={"delivery-way" + cnt} />
              </>
            )}
            {shop.s_is_packing > 0 && (
              <>
                <input
                  type="radio"
                  name={"order-way-radio" + cnt}
                  id={"wrap-way" + cnt}
                  value="1"
                  onChange={(e) => {
                    handleChange(e, e.currentTarget.checked, cnt);
                  }}
                  checked={checkRadio[cnt] == "wrap-way" + cnt ? true : false}
                />
                <label htmlFor={"wrap-way" + cnt} />
              </>
            )}

            {shop.s_is_parcel > 0 && (
              <>
                <input
                  type="radio"
                  name={"order-way-radio" + cnt}
                  id={"parcel-way" + cnt}
                  value="5"
                  onChange={(e) => {
                    handleChange(e, e.currentTarget.checked, cnt);
                  }}
                  checked={checkRadio[cnt] == "parcel-way" + cnt ? true : false}
                />
                <label htmlFor={"parcel-way" + cnt} />
              </>
            )}
          </>
        )}
      </div>
    );
  };

  const MenuOptionCate = ({ optCateList }) => {
    return optCateList.map((optCate, index) => {
      return (

        <li key={optCate.moc_idx + index} style={{ display: 'none' }}>
          <span className="opction-class">{optCate.moc_name}</span>
          <MenuOptionList optList={optCate.optionArray} />
        </li>
      );
    });
  };
  const MenuOptionList = ({ optList }) => {
    return optList.map((opt, index) => {
      return (
        <div className="opciton-price-box" key={opt.mo_idx + index} >
          <span className="opction-name">
            <p>└</p>
            {opt.mo_name}
          </span>
          <span>
            <p>
              {opt.mo_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
            </p>
            <p>{opt.mo_count}개</p>
          </span>
        </div>
      );
    });
  };

  const MenuList = ({ menuList }) => {
    const mList = menuList.map((menu, index) => {
      return (
        <ul className="order-content-list" key={menu.m_idx + index}>
          <div className="order-menu-name-box">
            <span className="menu-function" style={{fontWeight: "100",fontSize: "6px",width: "100%"}}>
              <input
                type="checkbox"
                id={"menu-check" + menu.m_idx + index}
                name={"order-menu-checkbox" + index}
                className="order-menu-checkbox"
                // value={menu.m_idx}
                data-bmidx={menu.bm_idx}
                onChange={(e) => {
                  changeHandler(e.currentTarget.checked, menu.bm_idx, e);
                }}
                checked={checkedInputs[menu.bm_idx] ? true : false}
              />
              <label htmlFor={"menu-check" + menu.m_idx + index}></label>
              <span className="menu-name">{menu.m_name}</span>
            </span>
            <span className="menu-price">
                  
              <p >
                {menu.optionCateArray.length > 0
                  ? menu.totalPrice
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : menu.m_price * menu.m_count
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                원
              </p>
              
              <button className="count-btn" onClick={minusClickEvent} style={{ backgroundImage: 'none' }}>
                -
              </button>
              
              <p className="count-num" data-price={menu.m_price}>{menu.m_count}</p>개
              <button className="count-btn" onClick={plusClickEvent} style={{ backgroundImage: 'none' }}>
                +
              </button>
              <button
                className="order-cancel-button"
                onClick={(e) => basketDeleteHandler(e, menu.bm_idx)}
              ></button>
            </span>
          </div>
          {menu.optionCateArray.length > 0 && (
            <MenuOptionCate optCateList={menu.optionCateArray} />
          )}
        </ul>
      );
    });
    return mList;
  };

  const GiftGuide = () => {
    const giftGuide = basketList.map((shop, index) => {
      if (shop.s_idx == "10363") {
        return (
          <>
            <p>미사용 기프티콘의 환불은 구매 후 5일 이내에 가능합니다.</p>
            <p>기프티콘의 유효기간은 30일이며 유효기간 종료 후</p>
            <p>환불/기간연장은 불가능합니다.</p>
          </>
        );
      }
    });
    return giftGuide;
  };

  const ShopComponent = () => {
    const shopList = basketList.map((shop, index) => {
      return (
        <div className="order-way-box" key={shop.s_idx}>
          <div className="shop-folder">
            <div className="shop-name">
              <span className="order-title">{shop.s_name}</span>
              <button
                className="black-btn"
                onClick={(e) => basketDeleteHandler(e, shop.s_idx, true)}
              ></button>
            </div>
            <button className="drop" onClick={folderClickEvent}></button>
          </div>
          <div className="order-list-house">
            <div className="order-list-wrap" data-sidx={shop.s_idx}>
              <DelivType shop={shop} cnt={index} />
              <div className="order-content">
                <MenuList menuList={shop.rows} />
                <button
                  className="add-menu"
                  onClick={() => {
                    menuAddClickHandler(shop);
                  }}
                >
                  + 쇼핑 계속하기
                </button>
              </div>
            </div>
            <div className="bottomline"></div>
          </div>
        </div>
      );
    });
    return shopList;
  };

  const menuAddClickHandler = (shop) => {
    /*     if (shop.c2_idx == 21) { // 기프티콘 샵 메뉴 추가시 상품 화면으로 이동
      
    } else {
      navigate("/order/shop", { state: { shopInfo: shop } });
    } */

    navigate("/order");
  };
 
  const paymentClickHandler = async () => {
    const targetList = document.querySelectorAll(
      ".menu-function input:checked"
    );
    if (targetList.length < 1) {
      alert("결제하실 상품을 선택해주세요.");
      return;
    }
    let buyList = [];
    for (let i = 0; i < targetList.length; i++) {
      const ele = targetList[i];
      const odOption = ele
        .closest(".order-list-wrap")
        .querySelector('[name^="order-way-radio"]:checked')?.value;
      if (!odOption) {
        ele.closest(".order-way-box").scrollIntoView();
       //alert("옵션을3 선택해주세요.");
       // return;
      }
      const sIdx = ele.closest(".order-list-wrap").dataset.sidx;
      
      if (sIdx == "10363") {
        // 기프티콘 샵 s_idx
       
        console.log("start", userInfo.mb_auth);
        const res = await getMemberEventHandler();
        console.log("res :: ", res);
        if (res.mb_auth <= 0) {
          console.log(4);
          if (window.confirm("기프티콘 주문은 휴대폰 인증 후 가능합니다.")) {
            navigate("/account/JustAuth", {
              state: {
                mbId: res.mb_id,
                mbPhone: res.mb_phone,
              },
            });
            return;
          } else {
            return;
          }
        }
      }

      const bmIdx = ele.dataset.bmidx;
      const mCount = ele.closest(".order-menu-name-box").querySelector(".count-num").innerText;
     // console.log(mCount);
      buyList.push({ sIdx: sIdx, odOption: odOption, bmIdx: bmIdx,mCount:mCount });
    }
    
    setBuyBasket({ dataList: buyList })
      .then((res) => {
        navigate("/order/payment");
      })
      .catch((err) => {
        alert(err);
        console.error(err);
      });
  };

  const getMemberEventHandler = () => {
    return getMemberInfo()
      .then((res) => {
        console.log(1);
        setUserInfo(res);
        console.log(2);
        console.log(3);
        return res;
      })
      .catch((err) => {
        console.error("payment: err :: ", err);
        //에러처리필요
      });
  };

  return (
    <section className="order-history-frame">
      <span className="order-frame-title oft-btm">장바구니</span>

      {basketList && <ShopComponent />}

      <div className="final-confirmation">
        <div className="payment-result">
          <span className="total-payment-result">
            <p>총 결제금액</p>
            <p className="tAmt">
              {cAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
            </p>
          </span>
        </div>
        <div className="kiikey-guide">
          <p>이쁨은 통신판매 중개자이며 통신판매의 당사자가 아닙니다.</p>
          <p>따라서 이쁨은 상품거래 정보 및 거래에 책임을 지지 않습니다.</p>
          {basketList && <GiftGuide />}
        </div>
        <button className="payment-button" onClick={paymentClickHandler}>
          <span className="tAmt">
            {cAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
          </span>{" "}
          결제하기
        </button>
      </div>
    </section>
  );
};

export default OrderConfirm;
