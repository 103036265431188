import "components/order/style/OrderDetail.css";

const OrderRequest = ({ payIndex, od_option }) => {
  return (
    <div className="wrap_frame">
      <div className="order-request-wrap">

        <div className="order-request" >
          <span className="order-title-light">
            <b>요청</b>사항
          </span>
          <span className="order-title-light">
           <b>부탁</b>드려요!
          </span>
          <input
            type="text"
            id="request-text"
            className="ceo-msg"
            placeholder="아산, 서울사무실 중 원하는 수령지를 요청사항에 남겨 주세요"
          />

          <ul className="request-list">
            {/* <li>
            <input type="checkbox" id="request8" name="request8" />
            <label htmlFor="request8"></label>
            <span>다음에도 사용</span>
          </li> */}
          </ul>
          </div>
          <div className="order-request" style={{display:"none"}}>
          {od_option > 1 && (
            <>
              <span className="order-title-light">
                <b className="request_underline2">배송기사</b>님 <b>부탁</b>
                드려요!
              </span>
              <input
                type="text"
                id="request-text"
                className="rider-msg"
                placeholder="예) 운전 조심하세요!"
              />
            </>
          )}
          <ul className="request-list">
            {/* <li>
            <input type="checkbox" id="request4" name="request4" />
            <label htmlFor="request4"></label>
            <span>다음에도 사용</span>
          </li> */}
            <li>
              <input
                type="checkbox"
                id={"request5" + payIndex}
                name={"request5" + payIndex}
                className="need-fork"
              />
              <label htmlFor={"request5" + payIndex}></label>
              <span>문앞배송 부탁드립니다</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OrderRequest;
