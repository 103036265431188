import { setOrder } from "service/OrderService";

import "components/order/style/OrderDetail.css";

const InputPayFinalConfirm = ({
  location,
  usePoint,
  isPorc,
  setIsProc,
  setIsCreate,
  setOdIdxList,
}) => {
  const orderCreateEvent = () => {
    if (isPorc) {
      alert("이미 결제가 진행중입니다.");
      return;
    }

    let pay_type = document.querySelector(
      'input[name="payment-type"]:checked'
    ).value;

    if (location.state.cAmt - usePoint < 1) {
      pay_type = "3";
    }

    let tempList = [];
    let multipay = false;

    const article = document.querySelector(".order-wrap" + location.state.sIdx);
    const safeNum =
      article.querySelector('input[id^="safe-number"')?.checked || false;

    tempList.push({
      s_idx: location.state.sIdx,
      od_option: location.state.odOption,
      sp_pay_type: pay_type,
      od_use_point: usePoint,
      od_msg_rider: "",
      od_msg_ceo: "",
      od_is_use_v_hp: safeNum,
      sp_product_amt: location.state.cAmt,
      od_delivery_tip: 0,
      needFork: false,
    });

    if (tempList.length > 1 && pay_type > 3 && multipay) {
      multipay = true;
    } else {
      multipay = false;
    }

    console.log("tempList :: " + tempList);

    setIsCreate(false);
    setIsProc(true);
    setOrder({
      orderList: tempList,
      multipay: multipay,
    })
      .then((res) => {
        setOdIdxList(res.od_idx_list);
      })
      .catch((err) => {
        console.error("setOrder catct", err);
        if (err && err.msg) {
          alert(err.msg);
          setIsProc(false);
        }
      });
  };

  const PointChargeGuide = () => {
    return (
      <>
        <p>포인트 지급 후 환불은 불가능 합니다.</p>
      </>
    );
  };

  return (
    <div className="final-confirmation">
      <div className="underline"></div>
      <div className="wrap_frame">
        <div className="payment-result">
          <div className="chek_box_area">
            {/* <input
              type="checkbox"
              id="address-checkbox5"
              name="address-checkbox"
            />
            <label htmlFor="address-checkbox5" />
            <span>전체결제하기</span> */}
          </div>

          <p className="pay_number">결제금액</p>
          <ul className="payment-result-list">
            <li>
              <span>주문금액</span>
              <span>
                {location.state.cAmt
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                <span className="font-weght-light2">원</span>
              </span>
            </li>
            {!location.state.is_private && (
              <li>
                <span>포인트</span>
                <span>
                  {usePoint > 0 && "-"}
                  {usePoint
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  <span className="font-weght-light2">원</span>
                </span>
              </li>
            )}
          </ul>
          <span className="total-payment-result">
            <p>총 결제금액</p>
            <p>
              {/* 무조건 2000원 할인 CGV */}
              {(location.state.cAmt - usePoint)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
              <span className="font-weght-light3">원</span>
            </p>
          </span>
        </div>
      </div>
      <div className="kiikey-guide">
        <p>이쁨은 통신판매 중개자이며 통신판매의 당사자가 아닙니다.</p>
        <p>따라서 이쁨은 상품거래 정보 및 거래에 책임을 지지 않습니다.</p>
        {<PointChargeGuide />}
      </div>
      <button className="payment-button" onClick={orderCreateEvent}>
        <span>
          {(location.state.cAmt - usePoint)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </span>
        원 결제하기
      </button>
    </div>
  );
};

export default InputPayFinalConfirm;
