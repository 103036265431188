import { useState, useEffect } from "react";

import { REVIEW_IMG_PATH, serverErrorHandler } from "App-config";

import { getShopReview } from "service/OrderService";

import ShopGrade from "components/order/ShopGrade";
import StarCnt from "components/common/StarCountComponent";

import menutestImg from "src_assets/order_test_img.png";

import "components/order/style/ShopReview.css";

const ShopReview = ({ sIdx }) => {
  const [reviewList, setReviewList] = useState();

  useEffect(() => {
    if (sIdx) {
      getShopReview(sIdx)
        .then((res) => {
          console.log("review::::", res);
          if (res.success) {
            setReviewList(res.data);
          }
        })
        .catch((err) => {
          console.error(err);
          serverErrorHandler(err);
        });
    }
  }, []);

  const photoReviewClickEvent = (e) => {
    // console.log(e.target.checked);
    document.querySelectorAll(".review-show").forEach((ele) => {
      if (e.target.checked) {
        const target = ele.querySelector(".review-photo");
        if (!target) ele.classList.add("dis-none");
      } else {
        ele.classList.remove("dis-none");
      }
    });
  };

  return (
    <>
      <ShopGrade
        starData={reviewList?.starData}
        totalCnt={reviewList?.totalCnt}
      />
      <div className="shop-review">
        <div className="review-count">
          <ul className="review-class">
            <li>
              <span>전체리뷰 </span>
              <span>{reviewList?.totalCnt}개</span>
            </li>
            <li>
              <span>사장님 댓글 </span>
              <span>{reviewList?.ceoCnt}개</span>
            </li>
          </ul>
          <span className="review-select">
            <input
              type="checkbox"
              id="review-checkbox"
              name="review-checkbox"
              onChange={photoReviewClickEvent}
            />
            <label htmlFor="review-checkbox" />
            <p>포토리뷰만</p>
          </span>
        </div>

        {reviewList && <ReviewComponent reviews={reviewList.reviews} />}
      </div>
    </>
  );
};

const ReviewComponent = ({ reviews }) => {
  const reviewListComponent = reviews.map((review, index) => {
    return (
      <div className="review-show" key={review.r_idx + index}>
        <div className="review-account">
          <div className="account-info">
            <span className="account-img">
              <img src={menutestImg} alt="계정 이미지" />
            </span>
            <span className="account-detail">
              <p className="account-detail-text">{review.mb_id}</p>
              <p>
                <StarCnt totalCnt={review.r_star} reviewCnt={1} />
              </p>
            </span>
          </div>
          <div className="account-date">{review.r_datetime}</div>
        </div>

        {review.r_img_list.length > 0 && (
          <ReviewImgList r_img_list={review.r_img_list} />
        )}

        <div className="review-text">
          <span className="order-text">{review.r_content}</span>
        </div>
        {review.menu_list.length > 0 && (
          <ul className="review-order-history">
            <li className="review-order-title">주문내역</li>
            <MenuNameComponent menu_list={review.menu_list} />
          </ul>
        )}
        {/* <div className="report">
          <button className="report-button">신고</button>
        </div> */}

        {review.r_ceo_content && (
          <div className="ceo-comment-box">
            <span className="ceo-commnet">사장님 댓글</span>
            <div className="ceo-comment-text">{review.r_ceo_content}</div>
          </div>
        )}
      </div>
    );
  });
  return reviewListComponent;
};

const MenuNameComponent = ({ menu_list }) => {
  return menu_list.map((menuName, index) => <li key={index}>{menuName}</li>);
};

const ReviewImgList = ({ r_img_list }) => {
  const imgList = r_img_list.map((img, index) => (
    <span className="review-photo-right-img-box" key={index}>
      <img src={REVIEW_IMG_PATH + img} alt="리뷰 이미지" />
    </span>
  ));
  return <div className="review-photo">{imgList}</div>;
};

export default ShopReview;
