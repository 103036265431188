import { async } from "@firebase/util";
import { USER_API_PATH, USER_DTO, USER_WALLET_API_PATH } from "App-config";
import { call, customAlert } from "service/ApiService";

export const loginCheck = async () => {
  try {
    const response = await call(USER_API_PATH, "POST", {
      action: "loginCheck",
    });
    return response;
  } catch (error) {
    // console.error("loginCheck err:", error);
    return false;
  }
};

export const getUserQnaList = async () => {
  try {
    const response = await call(USER_API_PATH, "POST", {
      action: "getUserQnaList",
    });
    if (!response.success) {
      console.error("getUserQnaList fail:", response.msg);
      customAlert(response.msg);
    }
    return response;
  } catch (error) {
    console.error("getUserQnaList err:", error);
    customAlert(error.msg);
    return false;
  }
};

export const getShopScrapList = async () => {
  try {
    const response = await call(USER_API_PATH, "POST", {
      action: "getShopScrapList",
    });
    if (!response.success) {
      console.error("getShopScrapList fail:", response.msg);
      customAlert(response.msg);
    }
    return response;
  } catch (error) {
    console.error("getShopScrapList err:", error);
    customAlert(error.msg);
    return false;
  }
};

export const getEventData = async () => {
  try {
    const response = await call(USER_API_PATH, "POST", {
      action: "getEventData",
    });
    if (!response.success) {
      console.error("getEventData fail:", response.msg);
      customAlert(response.msg);
    }
    return response;
  } catch (error) {
    console.error("getEventData err:", error);
    customAlert(error.msg);
    return false;
  }
};

export const deleteUser = async () => {
  const response = await call(USER_API_PATH, "POST", { action: "deleteUser" });
  return response;
};

export const modifyUserInfo = async (userDTO) => {
  userDTO.action = "modifyUserInfo";
  const response = await call(USER_API_PATH, "POST", userDTO);
  return response;
};

export const deleteReview = async ({ rIdx }) => {
  const response = await call(USER_API_PATH, "POST", {
    action: "deleteReview",
    r_idx: rIdx,
  });
  return response;
};

export const deleteInquire = async ({ iqIdx }) => {
  const response = await call(USER_API_PATH, "POST", {
    action: "deleteInquire",
    iq_idx: iqIdx,
  });
  return response;
};


export const writeMyEvent = async (eventDTO) => {
  eventDTO.action = "writeMyEvent";
  const response = await call(USER_API_PATH, "POST", eventDTO, true);
  return response;
};

export const deleteMyEvent = async ({ esIdx }) => {
  const response = await call(USER_API_PATH, "POST", {
    action: "deleteMyEvent",
    esIdx: esIdx,
  });
  return response;
};

export const getMyEventData = async ({ esIdx }) => {
  const response = await call(USER_API_PATH, "POST", {
    action: "getMyEventData",
    esIdx: esIdx,
  });
  return response;
};

export const getEventContentData = async ({ esIdx }) => {
  const response = await call(USER_API_PATH, "POST", {
    action: "getEventContentData",
    esIdx: esIdx,
  });
  return response;
};

export const getReviewList = async () => {
  try {
    const response = await call(USER_API_PATH, "POST", {
      action: "getReviewList",
    });
    if (!response.success) {
      console.error("getReviewList fail:", response.msg);
      customAlert(response.msg);
    }
    return response;
  } catch (error) {
    console.error("getReviewList err:", error);
    customAlert(error.msg);
    return false;
  }
};

export const getInquireList = async () => {
  try {
    const response = await call(USER_API_PATH, "POST", {
      action: "getInquireList",
    });
    if (!response.success) {
      console.error("getInquireList fail:", response.msg);
      customAlert(response.msg);
    }
    return response;
  } catch (error) {
    console.error("getInquireList err:", error);
    customAlert(error.msg);
    return false;
  }
};

export const getTicketList = async () => {
  try {
    const response = await call(USER_API_PATH, "POST", {
      action: "getTicketList",
    });
    if (!response.success) {
      console.error("getTicketList fail:", response.msg);
      customAlert(response.msg);
    }
    return response;
  } catch (error) {
    console.error("getTicketList err:", error);
    customAlert(error.msg);
    return false;
  }
};


export const getPointHistoryList = async ({ term }) => {
  try {
    const response = await call(USER_API_PATH, "POST", {
      term: term,
      action: "getPointHistoryList",
    });
    if (!response.success) {
      console.error("getPointHistoryList fail:", response.msg);
      customAlert(response.msg);
    }
    return response;
  } catch (error) {
    console.error("getPointHistoryList err:", error);
    customAlert(error.msg);
    return false;
  }
};

export const delAddrHistory = async ({ ahIdx, isAll }) => {
  try {
    const response = await call(USER_API_PATH, "POST", {
      ah_idx: ahIdx,
      is_all: isAll,
      action: "deleteAddrHistory",
    });
    if (!response.success) {
      console.error("deleteAddrHistory fail:", response.msg);
      customAlert(response.msg);
    }
    return response;
  } catch (error) {
    console.error("deleteAddrHistory err:", error);
    customAlert(error.msg);
    return false;
  }
};

export const getAddrHistory = async (page) => {
  try {
    const response = await call(USER_API_PATH, "POST", {
      action: "getAddrHistoryList",
      page: page,
    });
    if (!response.success) {
      console.error("getAddrHistoryList fail:", response.msg);
      customAlert(response.msg);
    }
    return response;
  } catch (error) {
    console.error("getAddrHistoryList err:", error);
    customAlert(error.msg);
    return false;
  }
};

export const getPopupNoticeList = async () => {
  try {
    const response = await call(USER_API_PATH, "POST", {
      action: "getPopupNoticeList",
    });
    if (!response.success) {
      console.error("getPopupNoticeList fail:", response.msg);
      customAlert(response.msg);
    }
    return response;
  } catch (error) {
    console.error("getPopupNoticeList err:", error);
    customAlert(error.msg);
    return false;
  }
};

export const getUserNoticeList = async () => {
  try {
    const response = await call(USER_API_PATH, "POST", {
      action: "getUserNoticeList",
    });
    if (!response.success) {
      console.error("getUserNoticeList fail:", response.msg);
      customAlert(response.msg);
    }
    return response;
  } catch (error) {
    console.error("getUserNoticeList err:", error);
    customAlert(error.msg);
    return false;
  }
};

export const setLocation = async (addrDTO) => {
  addrDTO.action = "setLocation";
  try {
    const response = await call(USER_API_PATH, "POST", addrDTO);
    if (!response.success) {
      console.error("setLocation fail:", response.msg);
      customAlert(response.msg);
    }
    return response;
  } catch (error) {
    console.error("setLocation err:", error);
    customAlert(error.msg);
    return false;
  }
};

export const signup = async (userDTO) => {
  userDTO.action = "signup";
  const response = await call(USER_API_PATH, "POST", userDTO);
  return response;
};

export const signin = async (userDTO) => {
  userDTO.action = "signin";
  try {
    const response = await call(USER_API_PATH, "POST", userDTO);
    // window.sessionStorage.setItem("userDTO", JSON.stringify(response.data));
    return response;
  } catch (error) {
    console.error("signin err:", error);
    alert(error.msg);
    return USER_DTO;
  }
};

export const signout = async () => {
  try {
    const response = await call(USER_API_PATH, "POST", { action: "signout" });
    if (!response.success) {
      console.error("signout fail:", response.msg);
      customAlert(response.msg);
    }
    return response;
  } catch (error) {
    console.error("signout err:", error);
    customAlert(error.msg);
    return false;
  }
};

export const updateAccount = async (userDTO) => {
  userDTO.action = "updateAccount";
  try {
    const response = await call(USER_API_PATH, "POST", userDTO);
    if (!response.success) {
      console.error("idCheck fail:", response.msg);
      customAlert(response.msg);
    }
    return response;
  } catch (error) {
    console.error("idCheck err:", error);
    customAlert(error.msg);
    return error;
  }
};

export const idCheck = async (inId) => {
  try {
    const response = await call(USER_API_PATH, "POST", {
      action: "duplicateIDCheck",
      inId: inId,
    });
    if (!response.success) {
      console.error("idCheck fail:", response.msg);
      customAlert(response.msg);
    }
    return response;
  } catch (error) {
    console.error("idCheck err:", error);
    customAlert(error.msg);
    return error;
  }
};

export const duplicatePhoneCheck = async ({ _userPhone, pType, rType }) => {
  const response = await call(USER_API_PATH, "POST", {
    action: "duplicatePhoneCheck",
    phoneNum: _userPhone,
    pType: pType,
    rType: rType,
  });
  return response;
};

export const findLoginId = async (userDTO) => {
  userDTO.action = "findLoginId";
  const response = await call(USER_API_PATH, "POST", userDTO);
  return response;
};

export const checkPassword = async (passwd) => {
  const response = await call(USER_API_PATH, "POST", {
    action: "checkPassword",
    passwd: passwd,
  });
  return response;
};

export const passwordChange = async (userDTO) => {
  userDTO.action = "passwordChange";
  const response = await call(USER_API_PATH, "POST", userDTO);
  return response;
};

export const getMemberInfo = async () => {
  try {
    const response = await call(USER_API_PATH, "POST", {
      action: "getMemberInfo",
    });
    if (!response.success) {
      console.error("getMemberInfo fail:", response.msg);
      customAlert(response.msg);
    }
    return response.data;
  } catch (error) {
    console.error("getMemberInfo err:", error);
    customAlert(error.msg);
    return false;
  }
};

export const getAddrApi = async (position) => {
  position.action = "getAddrApi";
  try {
    const response = await call(USER_API_PATH, "POST", position);
    if (!response.success) {
      console.error("getAddrApi fail:", response.msg);
      customAlert(response.msg);
    }
    return response;
  } catch (error) {
    console.error("getAddrApi err:", error);
    customAlert(error.msg);
    return false;
  }
};

export const getAgreeList = async () => {
  const test = await call(USER_API_PATH, "POST", {
    action: "getAgreements",
  });

  return test;
};

export const findPassword = async (userDTO) => {
  userDTO.action = "findPassword";
  const response = await call(USER_API_PATH, "POST", userDTO);
  return response;
};

export const phoneAuth = async () => {

  const response = await call(USER_API_PATH, "POST", {
    action: "phoneAuth"
  });
  return response;
};

export const fcmAuthLog = async ({ _userPhone, error, rType, pType }) => {

  const response = await call(USER_API_PATH, "POST", {
    action: "fcmAuthLog",
    userPhone: _userPhone,
    log: error,
    ase_p_type: pType,
    ase_r_type: rType
  });
  return response;
};

export const getAdministrativeArea = async () => {

  const response = await call(USER_WALLET_API_PATH, "POST", {
    action: "getAdministrativeArea"
  });
  return response;
};

export const getMyWallet = async () => {
  const response = await call(USER_WALLET_API_PATH, "POST", {
    action: "getMyWallet"
  });
  return response;
};

export const createPrivateBarcode = async (walletDTO) => {
  walletDTO.action = 'createPrivateBarcode';

  const response = await call(USER_WALLET_API_PATH, "POST", walletDTO);
  return response;
};

export const createPublicBarcode = async (walletDTO) => {
  walletDTO.action = 'createPublicBarcode';

  const response = await call(USER_WALLET_API_PATH, "POST", walletDTO);
  return response;
};

export const chargePoint = async (walletDTO) => {
  walletDTO.action = 'chargePoint';

  const response = await call(USER_WALLET_API_PATH, "POST", walletDTO);
  return response;
};

export const getBarcodeData = async (barcodeDTO) => {
  barcodeDTO.action = 'getBarcodeData';

  const response = await call(USER_WALLET_API_PATH, "POST", barcodeDTO);
  return response;
}

export const getBarcodeHistory = async (barcodeDTO) => {
  barcodeDTO.action = 'getBarcodeResult';

  const response = await call(USER_WALLET_API_PATH, "POST", barcodeDTO);
  return response;
}

export const barcodeRefund = async (barcodeDTO) => {
  barcodeDTO.action = 'barcodeRefund';

  const response = await call(USER_WALLET_API_PATH, "POST", barcodeDTO);
  return response;
}
