import "components/common/style/Header.css";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import stateUtilityInstance from "../../TestClass";
import { isKiikeyApp, IS_ANDROID, IS_IOS } from "App-config";
import { async } from "@firebase/util";

const Header = () => {
  const navigate = useNavigate();
  //const title = stateUtilityInstance.getPropertyByName('title');
  
  const backBtnClickEvent = (e) =>{
    console.log("클릭");
    //console.log(window.history.length);
    //console.dir(window.history);
    // let test = window.NativeInterface.historyBack();
    // console.log(test);
    // console.log(window.location.pathname);
    // console.dir(window.history.state);
    if(window.location.pathname == '/order/payResult'){
      console.log ('홈');
      if(isKiikeyApp){
        if(IS_IOS){
          window.webkit.messageHandlers.callBackHandler.postMessage({
            action: "goClose"
          });
        }
        if(IS_ANDROID){
          if(window.location.pathname != '/order'){
            window.KiikeyWebView.goBack();
          }
        }
      }
      else{
        navigate('/order', {replace : true});
      }
    }

    // if(window.history.state && window.location.pathname == '/order/shop' && window.history.state.idx < 2){
    //   navigate('/order', {replace : true});
    // }

    // if(document.referrer.indexOf('php') != -1){
    //   navigate('/order', {replace : true});
    // }
    
    if(window.history.state && window.history.state.idx > 0){
      
      if(window.location.pathname != '/order'){
        window.history.back();
      }
    }
    else{
      if(isKiikeyApp){
        if(IS_IOS){
          if(window.location.pathname != '/order'){
            window.webkit.messageHandlers.callBackHandler.postMessage({
              action: "goClose"
            });
          }
        }
        if(IS_ANDROID){
          if(window.location.pathname != '/order'){
            window.KiikeyWebView.goBack();
          }
        }
      }
      else{
        navigate('/order', {replace : true});
      }
    }
  }
  return (
    <div className="header-mobile-top">
      <div className="header-mobile-top-button" onClick={backBtnClickEvent}>
        <button></button>
      </div>
      <div className="header-mobile-top-text">
        <span id="mobileTitle"></span>
      </div>
    </div>
  );
};

export default Header;
