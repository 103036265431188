import { useNavigate } from "react-router-dom";

import "components/order/style/OrderDetail.css";
import { useEffect, useImperativeHandle } from "react";

const PaymentHeader = ({
  payIndex,
  userInfo,
  basket,
  basketDeleteHandler, 
  telCheckHandler,
  addrCheckHandler,
  directCheckHandler,
}) => {
  const navigate = useNavigate();  
 
  const PackingComponent = () => {
    return (
      <ul className="delivery-info-list" data-odopt={basket.od_option}>
        <li>
          <ul>
            <li>매장주소</li>
            <li>
              {" "}
              <p>{basket.s_addr}</p>
            </li>
          </ul>
        </li>
      </ul>
    );
  };
  const DelivComponent = () => {
    return (
      <>
        <ul className="delivery-info-list" data-odopt={basket.od_option}>
          <li>
            <div className="adress_area">
              <div className="adress_area-content">
                <span>주소확인</span>
                <input
                  type="checkbox"
                  id={"address-checkbox" + payIndex}
                  name={"address-checkbox" + payIndex}
                  //onChange={(e) => addrCheckHandler(e, basket)}
                  onClick={(e) => {
                    addrCheckHandler(e, basket, payIndex);
                  }}
                // checked={delFeeList[payIndex] > 0}
                />
                <label htmlFor={"address-checkbox" + payIndex} />
              </div>
              <button
                className="address-change" id= "addresschange"
                onClick={() => {
                  navigate("changeLocation");
                }}
              >
                주소변경
              </button>
            </div>
          </li>
          <li>
            <p id = {"mb_addr"+payIndex}>{userInfo.mb_addr}</p>
          </li>
        </ul>

        <ul className="phone-number-wrap">
        <div className="order_type">
                  <b>
                  연락처
                  </b>{" "}
                  정보
        </div>
                <li>
                <input
                  type="text"
                  id="tel-phone"
                  className="class-tel-phone"
                  placeholder={userInfo.mb_phone.toString()
                              }
                />                    
          </li>
          <li >
                  <input type="checkbox" id={"tel-checkbox" + payIndex}
                        name={"tel-checkbox" + payIndex}
                         onClick={(e) => {
                          telCheckHandler(e, basket, payIndex);
                        }} />
                  <label htmlFor={"tel-checkbox" + payIndex}></label>
                  <span className="safe_number_check">
                  확인차 연락드릴 번호입니다. 확인 부탁트립니다.<br/>
                  숫자만 입력해주세요.
                  </span>
            </li> 
        </ul>
        <ul className="phone-number-wrap">
                  <div className="order_type">
                  <b>
                  성명
                  </b>
                </div>
                <li>
                <input
                  type="text"
                  id="mb-name"
                  className="class-mb-name"
                  placeholder={userInfo.mb_name}
                />  
                
                </li>
                <li >
                  <label htmlFor="safe-number2"></label>
                  <span className="safe_number_check">
                   받으실 성함입니다. 확인 부탁드립니다.<br/>
                  
                  </span>
                </li>
              </ul>
      </>
    );
  };

  return (
    <div className="delivery-info-box">
      <div className="underline"></div>
      <div className="wrap_frame">
        <div className="delivery-info">
          <div className="order_check_box">
            <div className="shop-name">
              <span className="order-title-light">{basket.s_name}</span>
              <button
                className="black-btn"
                onClick={() => {
                  basketDeleteHandler(basket.s_idx, true);
                }}
              ></button>
            </div>

            {/* <div className="chek_box_area">
              <input
                type="checkbox"
                id={"order-checkbox" + payIndex}
                name={"order-checkbox" + payIndex}
              />
              <label htmlFor={"order-checkbox" + payIndex} />
              <span>{basket.od_option == 4 ? "예약" : "주문"}하기</span>
            </div> */}
          </div>
          {basket.od_option == 6 || basket.od_option == 7 ? (
            ""
          ) : (
            <div className="order_type">
              <b>
                {basket.od_option == 1 && "포장"}
                {basket.od_option == 2 && "배송"}
                {basket.od_option == 4 && "예약"}
                {basket.od_option == 5 && "택배"}
              </b>{" "}
              정보
            </div>
          )}
          {(basket.od_option == 1 || basket.od_option == 4) && (
            <PackingComponent />
          )}
          {(basket.od_option == 2 || basket.od_option == 5) && (
            //<DelivComponent />
            <>
              <ul className="delivery-info-list" data-odopt={basket.od_option}>
                <li>
                  <div className="adress_area">
                    <div className="adress_area-content">
                      <span>주소확인</span>
                      <input
                        type="checkbox"
                        id={"address-checkbox" + payIndex}
                        name={"address-checkbox" + payIndex}
                        //onChange={(e) => addrCheckHandler(e, basket)}
                        onClick={(e) => {
                          addrCheckHandler(e, basket, payIndex);
                        }}
                      // checked={delFeeList[payIndex] > 0}
                      />
                      <label htmlFor={"address-checkbox" + payIndex} />
                      <span>직접수령</span>
                      <input
                        type="checkbox"
                        id={"direct-checkbox" + payIndex}
                        name={"direct-checkbox" + payIndex}
                        //onChange={(e) => addrCheckHandler(e, basket)}
                        onClick={(e) => {
                          directCheckHandler(e, basket, payIndex);
                        }}
                      // checked={delFeeList[payIndex] > 0}
                      />
                      <label htmlFor={"direct-checkbox" + payIndex} />
                    </div>

                    <button
                      className="address-change"
                      onClick={() => {
                        navigate("changeLocation");
                      }}
                    >
                      주소변경
                    </button>
                  </div>
                </li>
                <li>
                  <p id={"mb_addr"+payIndex}>{userInfo.mb_addr}</p>
                </li>
              </ul>
             
              <ul className="phone-number-wrap">
                  <div className="order_type">
                  <b>
                  연락처
                  </b>{" "}
                  정보
                </div>
                <li>
                <input
                  type="text"
                  id="tel-phone"
                  className="class-tel-phone"
                  placeholder={userInfo.mb_phone.toString()
                  }
                />                        
                </li>
                <li >
                  <input type="checkbox" id={"tel-checkbox" + payIndex}
                        name={"tel-checkbox" + payIndex}
                         onClick={(e) => {
                          telCheckHandler(e, basket, payIndex);
                        }} />
                  <label htmlFor={"tel-checkbox" + payIndex}></label>
                  <span className="safe_number_check">
                  물품 수령시 확인차 연락드릴 번호입니다. 확인 부탁트립니다.<br/>
                  숫자만 입력해주세요. 
                  </span>
                </li> 
                
              </ul>
              <ul className="phone-number-wrap">
                  <div className="order_type">
                  <b>
                  성명
                  </b>
                </div>
                <li>
                <input
                  type="text"
                  id="mb-name"
                  className="class-mb-name"
                  placeholder={userInfo.mb_name}
                />                                  
                </li>
                <li >
                  <label htmlFor="safe-number2"></label>
                  <span className="safe_number_check">
                   받으실 성함입니다. 확인 부탁드립니다.
                  </span>
                </li>
              </ul>
            </>
          )}
        </div>
      </div>
      <div className="underline"></div>
    </div>
  );
};

export default PaymentHeader;
