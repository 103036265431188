import { isKiikeyApp, IS_ANDROID, IS_IOS } from "App-config";

let instance;
let globalState = {
  color: "",
  title: "",
  selectedCateC3Index: 0,
  selectedCateTabIndex : 0,
  basketShopIdx :0,
  windowInnerHeight : window.innerHeight,
  dataloadTab : [],
  basketList : [],
  selectedMyWalletTabIndex : 0,
};

class StateUtility {
  constructor() {
    if (instance) {
      throw new Error("New instance cannot be created!!");
    }

    instance = this;
  }

  getPropertyByName(propertyName) {
    return globalState[propertyName];
  }
  setPropertyValue(propertyName, propertyValue) {
    globalState[propertyName] = propertyValue;
  }

  setMobileHeaderTitle(title){
    if(document.getElementById('mobileTitle'))
      document.getElementById('mobileTitle').innerHTML = title;
    if(isKiikeyApp){
        
        if(window.location.pathname == '/order'){
            if(IS_IOS){
              window.webkit.messageHandlers.callBackHandler.postMessage({
                action: "hideNav",
                data: "false",
              });
            }
            if(IS_ANDROID){
                window.KiikeyWebView.hideNavBar(false);
            }
        }
        else{
            if(IS_IOS){
              window.webkit.messageHandlers.callBackHandler.postMessage({
                action: "hideNav",
                data: "true",
              });
            }
            if(IS_ANDROID){
                window.KiikeyWebView.hideNavBar(true);
            }
        }
    }
  }

  setBasketShopIdx(s_idx){
    localStorage.setItem("setBasketShopIdx", s_idx);
  }

  getBasketShopIdx(){
    const basketShopIdx = localStorage.getItem("setBasketShopIdx");
    if(basketShopIdx){
        return basketShopIdx;
    }
    else{
        return 0;
    }
  }
}
let stateUtilityInstance = Object.freeze(new StateUtility());

export default stateUtilityInstance;

// class TestClass{
//     private static instanceRef: Person;
// } = (function() {
//     let instance;

//     let title_;
  
//     function init(){ // 싱글톤 객체를 리턴할 비공개 함수
//       return {
//         publictMethod: function() {
//           return 'public method';
//         },
//         publicProp: 'public variable',

//         setTitle: function(title){
//             title_ = title
//             return;
//         },

//         getTitle: function(){
//             return title_;
//         },
//       };
//     }
  
//     return {
//       getInstance: function() {
//         if (instance) {
//           return instance; // 있으면 그냥 반환
//         }
//         instance = init();
//         return instance; // 없으면 객체 생성 후 반환 (이해를 위해 명시적으로 나눔)
//       }
//     };
//   })();