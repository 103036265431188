import { useEffect, useState } from "react";
import { useNavigate, useHistory } from "react-router-dom";

//list

import allowbutton from "src_assets/allow-button.png";
import allowbuttonTop from "src_assets/allow-button-top-b.png";
// food
import menuCateImgjanbogo_mealkit from "src_assets/drawable/ic_jangbogo_mealkit.png";
import menuCateImgkorean_foot from "src_assets/drawable/ic_korean_food.png";
import menuCateImgwestern_food from "src_assets/drawable/ic_western_food.png";
import menuCateImgsnak_bar from "src_assets/drawable/ic_snack_bar.png";
import menuCateImgchicken from "src_assets/drawable/ic_chicken.png";
import menuCateImgpizza from "src_assets/drawable/ic_pizza.png";
import menuCateImgchinese_food from "src_assets/drawable/ic_chinese_food.png";
import menuCateImgjapanese_food from "src_assets/drawable/ic_japanese_food.png";
import menuCateImgpork_feet_bossam from "src_assets/drawable/ic_pork_feet_bossam.png";
import menuCateImgmidnight_snak from "src_assets/drawable/ic_midnight_snack.png";
import menuCateImgsteamed from "src_assets/drawable/ic_steamed.png";
import menuCateImglunch_box from "src_assets/drawable/ic_lunch_box.png";
import menuCateImgfast_food from "src_assets/drawable/ic_fast_food.png";
import menuCateImgdessert from "src_assets/drawable/ic_dessert.png";
//academy
import menuCateImgclass from "src_assets/drawable/ic_class.png";
import menuCateImgcombined_class from "src_assets/drawable/ic_combined_class.png";
import menuCateImgart_and_physical from "src_assets/drawable/ic_art_and_physical.png";
import menuCateImgstate_funded_academy from "src_assets/drawable/ic_state_funded_academy.png";
// hospital
import menuCateImgpediatrics from "src_assets/drawable/ic_pediatrics.png";
import menuCateImgmedicine from "src_assets/drawable/ic_medicine.png";
import menuCateImgdermatology from "src_assets/drawable/ic_dermatology.png";
import menuCateImgorthopedics from "src_assets/drawable/ic_orthopedics.png";
import menuCateImgdentist from "src_assets/drawable/ic_dentist.png";
import menuCateImgoriental_medicine_clinic from "src_assets/drawable/ic_oriental_medicine_clinic.png";
import menuCateImgobstetrician from "src_assets/drawable/ic_obstetrician.png";
import menuCateImgurology from "src_assets/drawable/ic_urology.png";
import menuCateImgfamily_medicine from "src_assets/drawable/ic_family_medicine.png";
import menuCateImganimal_hospital from "src_assets/drawable/ic_animal_hospital.png";
// k-beauty
import menuCateImg_menu_beauty from "src_assets/drawable/ic_menu_beauty.png";
import menuCateImg_cosmetics from "src_assets/drawable/ic_menu_cosmetics.png";
import menuCateImg_self_shop from "src_assets/drawable/ic_self_shop.png";
import menuCateImg_menu_nail from "src_assets/drawable/ic_menu_nail.png";
import menuCateImg_menu_tatto from "src_assets/drawable/ic_menu_tattoo.png";
import menuCateImg_menu_waxing from "src_assets/drawable/ic_menu_waxing.png";
import menuCateImg_menu_aesthetics from "src_assets/drawable/ic_menu_aesthetics.png";
import menuCateImg_menu_diet from "src_assets/drawable/ic_menu_diet.png";
import menuCateImg_menu_wedding from "src_assets/drawable/ic_menu_wedding.png";
import menuCateImg_menu_scalp from "src_assets/drawable/ic_menu_scalp.png";
import menuCateImg_menu_makeup from "src_assets/drawable/ic_menu_makeup.png";
// hotel
import menuCateImg_motel from "src_assets/drawable/ic_motel.png";
import menuCateImg_pension from "src_assets/drawable/ic_pension.png";
import menuCateImg_bed_and_breakfast from "src_assets/drawable/ic_bed_and_breakfast.png";
import menuCateImg_camping from "src_assets/drawable/ic_camping.png";
import menuCateImg_guest_house from "src_assets/drawable/ic_guest_house.png";
import menuCateImg_hotel from "src_assets/drawable/ic_hotel.png";
// clothing
import menuCateImg_mans_clothing from "src_assets/drawable/ic_mans_clothing.png";
import menuCateImg_womans_clothing from "src_assets/drawable/ic_womans_clothing.png";
import menuCateImg_kids_clothing from "src_assets/drawable/ic_kids_clothing.png";
import menuCateImg_mountain_clothing from "src_assets/drawable/ic_mountain_clothing.png";
import menuCateImg_training from "src_assets/drawable/ic_training.png";
import menuCateImg_sports_shose from "src_assets/drawable/ic_sports_shoes.png";
import menuCateImg_shoes from "src_assets/drawable/ic_shoes.png";
import menuCateImg_stuff from "src_assets/drawable/ic_stuff.png";
// mart
import menuCateImg_jangbogo from "src_assets/drawable/ic_jangbogo.png";
import menuCateImg_delivery_product from "src_assets/drawable/ic_delivery_product.png";
import menuCateImg_promotional_material from "src_assets/drawable/ic_promotional_material.png";
import menuCateImg_print from "src_assets/drawable/ic_print.png";
import menuCateImg_sign_banner from "src_assets/drawable/ic_sign_banner.png";
import menuCateImg_air_banner from "src_assets/drawable/ic_air_banner.png";
import menuCateImg_camping_stuff from "src_assets/drawable/ic_camping_stuff.png";
// qucick
import menuCateImg_food_quick from "src_assets/drawable/ic_food_quick.png";
import menuCateImg_entire_daegu_quick from "src_assets/drawable/ic_entire_daegu_quick.png";
import menuCateImg_chauffeur_service from "src_assets/drawable/ic_chauffeur_service.png";
import menuCateImg_delivery_service from "src_assets/drawable/ic_delivery_service.png";
import menuCateImg_moving_center from "src_assets/drawable/ic_moving_center.png";
import datetime from "src_assets/date-time_b.png";

import menuThumbbailImg from "src_assets/order_test_img.png";
// movie
import menuCateImg_movie from "src_assets/order_test_img.png";

import "components/order/style/OrderMenuList.css";
import { IMAGE_BASE_URL, isKiikeyApp } from "App-config";
import ShopSearchComponent from "./ShopSearchComponent";
import stateUtilityInstance from "../../TestClass";
import { throttleFunction } from "../../App-config";

const defaultImgEvent = (e) => {
  e.target.src = menuThumbbailImg;
};

const imgs = {
  94: menuCateImgjanbogo_mealkit,
  95: menuCateImgkorean_foot,
  96: menuCateImgwestern_food,
  97: menuCateImgsnak_bar,
  98: menuCateImgchicken,
  99: menuCateImgpizza,
  100: menuCateImgchinese_food,
  101: menuCateImgjapanese_food,
  108: menuCateImgpork_feet_bossam,
  102: menuCateImgmidnight_snak,
  107: menuCateImgsteamed,
  103: menuCateImglunch_box,
  105: menuCateImgfast_food,
  106: menuCateImgdessert,
  86: menuCateImgclass,
  87: menuCateImgcombined_class,
  88: menuCateImgart_and_physical,
  89: menuCateImgstate_funded_academy,
  76: menuCateImgpediatrics,
  77: menuCateImgmedicine,
  78: menuCateImgdermatology,
  79: menuCateImgorthopedics,
  80: menuCateImgdentist,
  81: menuCateImgoriental_medicine_clinic,
  82: menuCateImgobstetrician,
  83: menuCateImgurology,
  84: menuCateImgfamily_medicine,
  85: menuCateImganimal_hospital,
  110: menuCateImg_menu_beauty,
  91: menuCateImg_cosmetics,
  90: menuCateImg_self_shop,
  111: menuCateImg_menu_nail,
  112: menuCateImg_menu_tatto,
  113: menuCateImg_menu_waxing,
  114: menuCateImg_menu_aesthetics,
  115: menuCateImg_menu_diet,
  116: menuCateImg_menu_wedding,
  92: menuCateImg_menu_scalp,
  93: menuCateImg_menu_makeup,

  58: menuThumbbailImg,
  59: menuThumbbailImg,
  60: menuThumbbailImg,
  61: menuThumbbailImg,

  70: menuCateImg_motel,
  71: menuCateImg_pension,
  72: menuCateImg_bed_and_breakfast,
  73: menuCateImg_camping,
  74: menuCateImg_guest_house,
  75: menuCateImg_hotel,
  62: menuCateImg_mans_clothing,
  63: menuCateImg_womans_clothing,
  64: menuCateImg_kids_clothing,
  65: menuCateImg_mountain_clothing,
  66: menuCateImg_training,
  68: menuCateImg_sports_shose,
  69: menuCateImg_shoes,
  67: menuCateImg_stuff,

  18: menuCateImgkorean_foot,
  19: menuCateImgwestern_food,
  22: menuCateImgsnak_bar,
  23: menuCateImgchicken,
  24: menuCateImgpizza,
  25: menuCateImgchinese_food,
  26: menuCateImgjapanese_food,
  27: menuCateImgmidnight_snak,
  28: menuCateImglunch_box,
  29: menuCateImgfast_food,
  30: menuCateImgfast_food,
  31: menuCateImgdessert,

  32: menuCateImgkorean_foot,
  33: menuCateImgwestern_food,
  36: menuCateImgsnak_bar,
  37: menuCateImgchicken,
  38: menuCateImgpizza,
  39: menuCateImgchinese_food,
  40: menuCateImgjapanese_food,
  41: menuCateImgmidnight_snak,
  42: menuCateImglunch_box,
  43: menuCateImgfast_food,
  44: menuCateImgfast_food,
  45: menuCateImgdessert,

  46: menuCateImg_jangbogo,
  47: menuCateImgjanbogo_mealkit,
  48: menuCateImg_delivery_product,
  49: menuCateImg_promotional_material,
  50: menuCateImg_print,
  51: menuCateImg_sign_banner,
  52: menuCateImg_air_banner,
  109: menuCateImg_camping_stuff,

  53: menuCateImg_food_quick,
  54: menuCateImg_entire_daegu_quick,
  55: menuCateImg_chauffeur_service,
  56: menuCateImg_delivery_service,
  57: menuCateImg_moving_center,

  117: menuCateImg_movie,
};

const ShopCategory = ({
  IS_IOS,
  IS_ANDROID,
  isMobile,
  cateList,
  categoryClickEvent,
  searchKeyList,
  setTitle,
}) => {
  const navigate = useNavigate();

  //const history = useHistory();

  const [nowDate, setNowDate] = useState();
  const [isFocus, setIsFocus] = useState(false);
  const [hashTagList, setHashTagList] = useState([]);

  const hashTagAddToLocalStorage = (searchKey) => {
    const searchHashTagList = localStorage.getItem("searchHashTagList");
    let newSearchHashTagList = [];
    if (searchHashTagList) {
      newSearchHashTagList = searchHashTagList.split(",");
    } else {
      localStorage.setItem("searchHashTagList", []);
    }

    if (searchKey) {
      newSearchHashTagList.unshift(searchKey);
      newSearchHashTagList = new Set(newSearchHashTagList);
      newSearchHashTagList = [...newSearchHashTagList];
      if (newSearchHashTagList.length > 4) {
        // 최대 4개
        newSearchHashTagList.pop();
      }
    }

    localStorage.setItem("searchHashTagList", newSearchHashTagList);

    setHashTagList(newSearchHashTagList);
  };

  const gotoSearchKey = (searchKey) => {
    if (!isMobile) {
      hashTagAddToLocalStorage(searchKey);
      navigate("/order/search", {
        state: {
          title: "검색 결과",
          c3idx: 0,
          searchKey: searchKey,
          is_product: 0,
        },
      });
    }
  };
  const searchOnChangeEvent = (e) => {
    if (e.code === "Enter") {
      gotoSearchKey(e.target.value);
    } else if (e.type === "click") {
      gotoSearchKey(document.getElementById("order-search").value);
    }
  };

  useEffect(() => {
    //history.block();
    //document.getElementById('mobileTitle').innerText = '검색';
    stateUtilityInstance.setMobileHeaderTitle("검색");
    // stateUtilityInstance.setPropertyValue('title','검색');
    // console.log('setTitle');
    // console.log(stateUtilityInstance.getPropertyByName('title'));
    hashTagAddToLocalStorage();
  }, []);

  useEffect(() => {
    const now = new Date();
    setNowDate(
      `${now.getFullYear()}. ${String(now.getMonth() + 1).padStart(
        2,
        "0"
      )}. ${String(now.getDate()).padStart(2, "0")}.`
    );
  }, [searchKeyList]);

  // useEffect(() => {
  //   //console.log('카테 이쁨앱 :'+isKiikeyApp);
  //   if (isKiikeyApp) {
  //     if (IS_IOS) {
  //       //window.webkit.messageHandlers.scriptHandler.postMessage(message);
  //     }
  //     if (IS_ANDROID) {
  //       // 안드로이드
  //       window.KiikeyWebView.hideNavBar(false);
  //     }
  //   }
  // }, []);
  //console.log("searchKeyList");
  //console.dir(searchKeyList);
  const searchKeyComponents = searchKeyList.map((item, index) => {
    return (
      <li
        key={index}
        onClick={() => {
          gotoSearchKey(item.pk_keyword);
        }}
      >
        <span className="list-number">{index + 1}.</span>
        <span className="pop-name">{item.pk_keyword}</span>
      </li>
    );
  });
  return (
    <>
      <section
        className={"order-menu-list-frame" + (isKiikeyApp ? " kapp" : "")}
      >
        {/* <div className="order-menu-top-name">검색</div> */}
        <div>
        <div className="container">
             <span style={{width:"38%"}}>키워드검색:</span>
          <div className="order-search-box-wrap">
         
            
            <div className="order-search-box">
              <button
                className="order-search-button"
                onClick={searchOnChangeEvent}
              />
              <input
                id="order-search"
                placeholder="이쁨에서 검색하세요."
                onKeyUp={searchOnChangeEvent}
                onFocus={() => {
                  if (isMobile) {
                    navigate("/order/ShopSearch", {
                      state: {
                        searchKeyList: searchKeyList,
                      },
                    });
                  }

                  setIsFocus(true);
                }}
                onBlur={() => {
                  setTimeout(() => {
                    setIsFocus(false);
                  }, 200);
                }}
              />
            </div>
          </div>
          </div>
        </div>
        {hashTagList.length > 0 && (
          <ul className="popular-menu-box">
            {hashTagList.map((item, index) => {
              return (
                <li
                  key={index}
                  onClick={() => {
                    gotoSearchKey(item);
                  }}
                >
                  #{item}
                </li>
              );
            })}
          </ul>
        )}

        {/* 만약 popular-list 가 클릭이 되었다면 popular-search-result 클래스 이름을 '.popular-search-result_clicked' 로 변경 */}
        <div
          className={
            isFocus ? "popular-search-result clicked" : "popular-search-result"
          }
        >
          <div className="popular-result">
            <span className="popular-name">인기검색어</span>
            <ul className="popular-list">{searchKeyComponents}</ul>
          </div>
          <div className="popular-date-box">
            <span className="popular-date-img">
              <img src={datetime} alt="시간이미지"></img>
            </span>
            <span className="popular-date">{nowDate}</span>
          </div>
        </div>
        <CategoryComponent
          categoryClickEvent={categoryClickEvent}
          cateList={cateList}
        />
      </section>
    </>
  );
};

const CategoryComponent = ({ cateList, categoryClickEvent }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(-1); // 메뉴의 초기값을 false로 설정
  const toggleMenu = (target) => {
    if (isOpen == target) setIsOpen(-1);
    else setIsOpen(target); // on,off 개념 boolean
  };
  const goShop = (c2_idx, s_idx) => {
    const shop = {
      c2_idx: c2_idx,
      s_idx: s_idx,
    };
    navigate("/order/print", { state: { shopInfo: shop } });
  };
  //console.dir(cateList);
  const cateListMap = cateList.map((data, index) => {
    return (
      <>
        <li key={index}>
          <div
            className="order-list-head" style={{ display: "none" } /*임시로 숨김*/}
            onClick={() => {
              switch (data.c2data.c2_idx) {
                case "22":
                  goShop(data.c2data.c2_idx, "12525"); // 프린터 s_idx 수정 필요
                  break;
                case "23":
                  alert("준비중입니다.");
                  break;
                default:
                  toggleMenu(index);
                  break;
              }
            }}
          >
            <span className="list-head-text">
              <p className={isOpen === index ? "textline" : ""}>
                {data.c2data.c2_name}
              </p>
            </span>
            {data.c2data.c2_idx != "22" && data.c2data.c2_idx != "23" && (
              <button className="order-list-button">
                <img
                  src={isOpen === index ? allowbuttonTop : allowbutton}
                  alt="버튼이미지"
                ></img>
              </button>
            )}
          </div>

          <MiddleCategoryComponent
            disNone={isOpen === index ? "" : "dis-none textline"}
            c2idx={data.c2data.c2_idx}
            categoryClickEvent={categoryClickEvent}
            c3data={data.c3data}
          />
        </li>
      </>
    );
  });

  return (
    <>
      <ul className="order-menu-list">{cateListMap}</ul>
    </>
  );
};

const MiddleCategoryComponent = ({
  disNone,
  c2idx,
  c3data,
  categoryClickEvent,
  
}) => {
  const middleCateList = c3data.map((data, index) => {
    //console.dir(data);
    return (
      <li onClick={() => categoryClickEvent({ data, c3data })} key={index}>
        <div className="detail-img">
          <img
            src={IMAGE_BASE_URL + "/mainCateImg/" + data.c3_img}
            //src={imgs[data.c3_idx]}
            onError={defaultImgEvent}
            alt="메뉴이미지"
          ></img>
        </div>
        <div>
          <span>{data.c3_name}</span>
        </div>
      </li>
    );
  });
  return (
    <ul className={"menu-list-datail " + ""} id={c2idx}>
      {middleCateList}
    </ul>
  );
};

export default ShopCategory;
