import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import testimg from "src_assets/item_img.png";

import Pagination from "components/common/Pagination";
// import KakaoAddrPopup from "components/common/KakaoAddrPopup";
import {
  cancelOrder,
  deleteOrder,
  getOrderHistory,
} from "service/OrderService";

import "components/mykiikey/style/Orderlist.css";
import "components/mykiikey/style/Mykiikey.css";
import stateUtilityInstance from "../../TestClass";

import face from "src_assets/icon/face.png";
import twi from "src_assets/icon/twi.png";
import kakao from "src_assets/icon/kakao.png";
import you from "src_assets/icon/you.png";
import { NewLoading } from "components/common/NewLoading";

// 지호 220707 주문내역 ziho_ohl
// 2022-07-17 주문내역 가져오기 페이징처리, 로직변경
const OrderList = ({
  userInfo,
  setUserInfo,
  orderHistoryList,
  setOrderHistoryList,
  isMobile,
}) => {
  const navigate = useNavigate();

  const [share, setShare] = useState(null);
  const [isLoad, setIsLoad] = useState(false);

  const VIEW_LIMIT = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const offset = (currentPage - 1) * VIEW_LIMIT;
  const orderHistoryListLimit = isMobile
    ? orderHistoryList.slice(0, offset + VIEW_LIMIT)
    : orderHistoryList.slice(offset, offset + VIEW_LIMIT);

  const ReviewTime = ({ isState, isReviewSuc, review_time }) => {
    if (isReviewSuc) {
      return (
        <>
          {isState && <p>리뷰 쓰기는 주문 완료 이후 3개월 동안만 가능합니다.</p>}
          <p className="review-date">{review_time}</p>
        </>
      );
    } else {
      return (
        <>
          {isState && <p>리뷰쓰기 기간이 지났습니다.</p>}
          <p className="review-date">{review_time}</p>
        </>
      );
    }
  };

  const orderShareClickEvent = (data) => {
    setShare(data);
  };

  const orderDetailClickEvent = (od_idx) => {
    navigate("/mykiki/orderList/" + od_idx);
  };

  const orderReviewClickEvent = ({ od_idx, s_name, s_idx, c2_idx, c3_idx }) => {
    navigate("/mykiki/orderList/" + (c2_idx == "1" ? "review" : "reviewType"), {
      state: { od_idx, s_name, s_idx, c2_idx, c3_idx },
    });
  };

  const OrderState = ({ orderHistory }) => {
    let orderStat = "결제전";
    if (orderHistory.od_state == 1) {
      orderStat = "결제완료";
    } else if (orderHistory.od_state == 2) {
      orderStat = "접수완료";
    } else if (orderHistory.od_state == 3) {
      orderStat = "배송중";
    } else if (orderHistory.od_state == 4) {
      orderStat = "완료";
    } else if (orderHistory.od_state < 0) {
      orderStat = "취소완료";
    }

    if (
      (orderHistory.s_idx == 10228 && orderHistory.od_state == 1) ||
      (orderHistory.s_idx == 10228 && orderHistory.od_state == 2) ||
      (orderHistory.s_idx == 10228 && orderHistory.od_state == 3)
    ) {
      orderStat = "구매완료";
    } else if (orderHistory.s_idx == 10228 && orderHistory.od_state == 4) {
      orderStat = "사용완료";
    }

    return <span className="order-state">{orderStat}</span>;
  };

  const orderHistoryDeleteClickEvent = (orderHistory) => {
    deleteOrder({ od_idx: orderHistory.od_idx })
      .then((res) => {
        if (res.success) {
          let newOrderHistory = [...orderHistoryList];
          for (let index = 0; index < newOrderHistory.length; index++) {
            const element = newOrderHistory[index];
            if (orderHistory.od_idx === element.od_idx) {
              newOrderHistory.splice(index, 1);
              break;
            }
          }
          setOrderHistoryList(newOrderHistory);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const orderCnacelClickEvent = (od_idx) => {
    cancelOrder({ od_idx_list: [od_idx] })
      .then((res) => {
        if (res.success) {
          let newOrderHistory = [...orderHistoryList];
          for (let index = 0; index < newOrderHistory.length; index++) {
            const element = newOrderHistory[index];
            if (od_idx === element.od_idx) {
              let cancelOrder = newOrderHistory.splice(index, 1);
              cancelOrder[0].od_state = -1;
              cancelOrder[0].od_cancel_type = 1;
              newOrderHistory.splice(index, 0, cancelOrder[0]);
              setOrderHistoryList(newOrderHistory);
              break;
            }
          }
          let newUserInfo = { ...userInfo };
          newUserInfo.mb_point = newUserInfo.mb_point + res.data * 1;
          setUserInfo(newUserInfo);
        }
        alert("취소 신청이 완료되었습니다. 환불포인트:"+res.data+"점");
      })
      .catch((err) => {
        alert(err.msg);
        console.error(err);
      });
  };

  const liTagList = orderHistoryListLimit.map((orderHistory, index) => {
    // const order_day = new Date(orderHistory.od_datetime);
    const order_day = new Date(orderHistory.od_datetime.replace(" ", "T"));

    // 259200000 : unix timestamp millisecond 3day

    //const isReviewSuc = order_day.getTime() + 259200000 > Date.now();
    const now = new Date();
const threeMonthsFromNow = new Date(now.setMonth(now.getMonth() + 3));
 const unixTimestampMilliseconds = threeMonthsFromNow.getTime();
  const isReviewSuc = order_day.getTime() + unixTimestampMilliseconds > Date.now();

    return (
      <li key={index}>
        <div className="order-list-head">
          <div className="order-list-state">
            <OrderState orderHistory={orderHistory} />
            <span className="shop-name">{orderHistory.sp_product_nm}</span>
          </div>
          {(orderHistory.od_state > 1 || orderHistory.od_state < 0) && (
            <button
              className="order-list-delete" style={{ display: "none" }}
              onClick={() => {
                orderHistoryDeleteClickEvent(orderHistory);
              }}
            >
              X
            </button>
          )}
        </div>
        <span className="order-info">
          {/* 현재 시간과 비교 */}
          <ReviewTime
            isState={
              orderHistory.od_state > 0 && orderHistory.od_option < 6
                ? true
                : false
            }
            isReviewSuc={isReviewSuc}
            review_time={orderHistory.od_datetime}
          />
        </span>
        {orderHistory.c2_idx == 20 && orderHistory.od_state == 2 && (
          <>
            <span style={{ fontSize: 12, fontWeight: 500, color: "red" }}>
              환불요청은 고객센터로 문의해주세요.
            </span>
            {/* <span style={{ fontSize: 13, fontWeight: 500 }}>
              ※ 구매내역중 하나라도 사용시 환불 불가합니다.
            </span> */}
          </>
        )}

        <div className="order-list-function">
          <span className="order-list-buttons">
            {orderHistory.od_state > 3 && (
              <button style={{ display: "none" }}
                className="share-button"
                onClick={() => {
                  orderShareClickEvent(orderHistory);
                  // setShare(orderHistory);
                }}
              >
                공유하기
              </button>
            )}
            <button
              className="orderdetail-button"
              onClick={() => orderDetailClickEvent(orderHistory.od_idx)}
            >
              주문상세
            </button>
            {orderHistory.c2_idx != 20 && orderHistory.od_state == 1 && (
              <button
                className="order-cancle-button"
                onClick={() => orderCnacelClickEvent(orderHistory.od_idx)}
              >
                주문취소
              </button>
            )}
            {orderHistory.c2_idx == 20 && orderHistory.od_state == 1 && (
              <button
                className="order-cancle-button"
                onClick={() => orderCnacelClickEvent(orderHistory.od_idx)}
              >
                주문취소
              </button>
            )}
            {isReviewSuc &&
              orderHistory.od_option < 6 &&
              orderHistory.od_write_review < 1 &&
              orderHistory.od_state > 3 && (
                <button
                  className="review-button"
                  onClick={() =>
                    orderReviewClickEvent({
                      od_idx: orderHistory.od_idx,
                      s_name: orderHistory.s_name,
                      s_idx: orderHistory.s_idx,
                      c2_idx: orderHistory.c2_idx,
                      c3_idx: orderHistory.c3_idx,
                    })
                  }
                >
                  리뷰쓰기
                </button>
              )}
          </span>
        </div>
      </li>
    );
  });

  useEffect(() => {
    stateUtilityInstance.setMobileHeaderTitle("주문내역");
    getOrderHistory()
      .then((res) => {
        if (res.success) {
          setIsLoad(true);
          setOrderHistoryList(res.data);
        }
      })
      .catch((err) => {});
  }, []);

  const ShareModal = () => {
    return (
      <div className="share-modal">
        <button className="share-delete-btn" onClick={() => setShare(null)} />
        <ul>
          <li>
            <a href="https://www.facebook.com/campaign/landing.php?&campaign_id=1662308814&extra_1=s%7Cc%7C514638008096%7Ce%7C%ED%8E%98%EC%9D%B4%EC%8A%A4%20%EB%B6%81%7C&placement=&creative=514638008096&keyword=%ED%8E%98%EC%9D%B4%EC%8A%A4%20%EB%B6%81&partner_id=googlesem&extra_2=campaignid%3D1662308814%26adgroupid%3D69637362208%26matchtype%3De%26network%3Dg%26source%3Dnotmobile%26search_or_content%3Ds%26device%3Dc%26devicemodel%3D%26adposition%3D%26target%3D%26targetid%3Dkwd-303215285480%26loc_physical_ms%3D1009879%26loc_interest_ms%3D%26feeditemid%3D%26param1%3D%26param2%3D&gclid=Cj0KCQjwlemWBhDUARIsAFp1rLW6TYl0AW3w-nKnpvC2df5zT9IZCUUjwNR6jQRFI6Yq6K0cfPHIEZgaAo-oEALw_wcB">
              <img src={face} alt="페이스북"></img>
              <p>Facebook</p>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/?lang=ko">
              <img src={twi} alt="트위터"></img>
              <p>Twitter</p>
            </a>
          </li>
          <li>
            <a href="https://www.kakaocorp.com/page/service/service/KakaoTalk">
              <img src={kakao} alt="카카오"></img>
              <p>Kakao</p>
            </a>
          </li>
          <li>
            <a href="#">
              <img src={you} alt="유튜브"></img>
              <p>Youtube</p>
            </a>
          </li>
        </ul>
        <div className="url-box">
          <span className="url">{window.location.href}</span>
          <button className="url-copy-button">복사</button>
        </div>
      </div>
    );
  };

  const resultView = (
    <>
      {share && <ShareModal />}
      <ul className="daps-ul-list">
        {/* 지호 220707 주문내역 데이터 로딩 */}
        {liTagList}
      </ul>
      {/* <button className="info-chagne-confirm">저장</button> */}
      <Pagination
        isMobile={isMobile}
        limit={VIEW_LIMIT}
        offset={offset}
        type={"orderHistory"}
        totalCount={orderHistoryList.length}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  );

  return (
    <div className="daps-frame">
      {isLoad ? resultView : <NewLoading tabState={0} index={0} />}
    </div>
  );
};

export default OrderList;
