import ShopMenuFood from "components/order/ShopMenuFoodComponent";

import Shopinfo from "components/order/ShopInfoComponent";
import ShopReview from "components/order/ShopReview";

import "components/order/style/MenuIntro.css";
import "components/order/style/ShopMenuFood.css";
import "components/order/style/ShopMenuGrade.css";

// 가게 메뉴 탭 쪽 따로 컴포넌트로 뺌
const ShopMenuInfo = ({
  shopInfo,
  menuList,
  menuRef,
  changeTab,
  setChangeTab,
  isCustomTable,
  setNavNumber,
  basketClickEvent,
}) => {
  const addCart = () => {
    console.log("addCart");
    basketClickEvent();
  };

  return (
    <div className="shop-menu-info">
      <div
        className="shop-menu-info-btn"
        style={{ display: changeTab == 2 && "none" }}
      >
        <button onClick={addCart}>+</button>
      </div>
      {shopInfo?.c2_idx != 20 && (
        <div className="info-select">
          <input
            type="radio"
            id="tab-change-menu"
            name="tab-change"
            defaultChecked
          />
          <label
            onClick={() => setChangeTab(1)}
            htmlFor="tab-change-menu"
            className="tab-menu"
          />
          <input type="radio" id="tab-change-info" name="tab-change" />
          <label
            onClick={() => setChangeTab(2)}
            htmlFor="tab-change-info"
            className="tab-info"
          />
        </div>
      )}
      {changeTab === 1 && (
        <>
          <div className="intro-menu">
            <div className="intro-menu-text">
              <span>
                {shopInfo.s_intro?.split("\n").map((line) => {
                  return (
                    <>
                      {line}
                      <br />
                    </>
                  );
                })}
              </span>
            </div>
          </div>
          <ShopMenuFood
            shopInfo={shopInfo}
            menuRef={menuRef}
            menuList={menuList}
            isCustomTable={isCustomTable}
            setNavNumber={setNavNumber}
          />
        </>
      )}
      {changeTab === 2 && (
        <>
          <div className="intro-menu">
            <div className="intro-menu-text">
              <span>
                {shopInfo.s_ad?.split("\n").map((line) => {
                  return (
                    <>
                      {line}
                      <br />
                    </>
                  );
                })}
              </span>
            </div>
          </div>

          <Shopinfo shopInfo={shopInfo} />
        </>
      )}
      {changeTab === 3 && <ShopReview sIdx={shopInfo.s_idx} />}
    </div>
  );
};

export default ShopMenuInfo;
