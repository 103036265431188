import { useNavigate } from "react-router-dom";

import {
  MENU_IMG_PATH,
  SHOP_C3_IDX_MAP,
  SHOP_CUSTOM_IMG_PATH,
} from "App-config";

import Beautydesigner from "components/order/Beautydesigner";

import menuThumbbailImg from "src_assets/order_test_img.png";

import "components/order/style/MenuCheck.css";
import "components/order/style/ShopMenuFood.css";
import useThemeObserver from "useThemeObserver";
import menuDefaultImg from "src_assets/menuDefaultImg.png";

const defaultImgEvent = (e) => {
  e.target.src = menuThumbbailImg;
};
const noMultiChk = (e) => {
  var obj = document.getElementsByName(e.target.name);
  for(var i=0; i<obj.length; i++){
    if(obj[i] != e.target){
      obj[i].checked = false;
    }
  }

};
const minusClickEvent = (e) => {
  const target = e.target.nextElementSibling;
  let val = parseInt(target.innerText);
  if (isNaN(val)) val = 0;
  target.innerText = val > 0 ? val - 1 : val;
};

const plusClickEvent = (e, maxCnt) => {
  const target = e.target.previousElementSibling;
  let val = parseInt(target.innerText);
  if (isNaN(val)) val = 0;
  if (!!maxCnt) {
    if (val >= maxCnt) {
      console.log("최대 옵션선택 초과");
      return false;
    }
  }
  target.innerText = val + 1;
};
// 3. 메뉴 옵션 섹션
const MenuOptionSection = ({ menuOptionData }) => {
  const Type2 = ({ menuOption }) => {
    return (
      <ul className="option-add dis-none" data-mocidx={menuOption.moc_idx}>
        <span className="order-frame-mini">{menuOption.moc_name}</span>
        <Type2LiList
          mOptions={menuOption.m_options}
          mMaxCount={menuOption.moc_max_count}
        />
      </ul>
    );
  };

  const Type2LiList = ({ mOptions, mMaxCount }) => {
    const liComponent = mOptions.map((opt, index) => (
      <li key={opt.mo_idx + index}>
        <div className="menu-item-add-name">
          <span>{opt.mo_name}</span>
          <span>
            {opt.mo_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
          </span>
        </div>
        <div className="menu-item-more">
          <button className="count-btn" onClick={minusClickEvent}>
            -
          </button>
          {/* <input className="count-num" value="0" readOnly /> */}
          <p
            className="count-num"
            data-moidx={opt.mo_idx}
            data-name={opt.mo_name}
            data-price={opt.mo_price}
          >
            0
          </p>
          <button
            className="count-btn"
            onClick={(e) => plusClickEvent(e, mMaxCount)}
          >
            +
          </button>
        </div>
      </li>
    ));
    return liComponent;
  };

  // radio
  const Type1 = ({ menuOption }) => {
    return (
      <ul className="option-radio dis-none" data-mocidx={menuOption.moc_idx}>
        <span className="order-frame-mini">{menuOption.moc_name}</span>
        <Type1LiList
          mOptions={menuOption.m_options}
          mocIdx={menuOption.moc_idx} 
        />
      </ul>
    );
  };

  // 지호 220706 상품 수량 증가 menu_cnt
  const Type1LiList = ({ mOptions, mocIdx }) => {
    const liComponent = mOptions.map((opt, index) => (
      <li key={opt.mo_idx + index}>
        <div className="radio-select-box">
          <input
            type="checkbox"
            id={"size-check" + opt.mo_idx}
            data-price={opt.mo_price}
            data-moidx={opt.mo_idx}
            name={"size-check-" + mocIdx} onClick={noMultiChk}
          />
          {mOptions[0]?.s_idx == 10228 ? (
            <span>{opt.mo_name} (2000원 할인 적용)</span>
          ) : (
            <span>{opt.mo_name}</span>
          )}
        </div>
        {/* cgv 상품 2000원 할인 */}
        <span className="radio-price">
          {mOptions[0]?.s_idx == 10228
            ? (opt.mo_price * 1 - 2000)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : opt.mo_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          원
        </span>
      </li>
    ));
    return liComponent;
  };

  const MenuOptionList = ({ menuOptionData }) => {
    return menuOptionData.map((menuOption, index) => {
      if (menuOption.moc_type == 1) {
        return <Type1 key={menuOption.m_idx + index} menuOption={menuOption} />;
      } else if (menuOption.moc_type == 2) {
        return <Type2 key={menuOption.m_idx + index} menuOption={menuOption} />;
      }
    });
  };

  return <MenuOptionList menuOptionData={menuOptionData} />;
};

// 2. 메뉴 섹션
const MenuSection = ({ shopInfo, menuData, isCustomTable }) => {
  const navigate = useNavigate();

  const optionToggleEvent = (e) => {
    if (e.target.checked) {
      e.target
        .closest("li")
        .querySelectorAll("ul")
        .forEach((el) => {
          if (el.className.includes("radio")) {
            el.querySelector("input").checked = true;
            // if (e.target.closest("li").dataset.price < 1) {}
          }
          el.classList.remove("dis-none");
        });
    } else {
      e.target
        .closest("li")
        .querySelectorAll("ul")
        .forEach((el) => {
          if (!el.classList.contains("dis-none")) el.classList.add("dis-none");
        });
    }
  };
  const MenuImgComponents = ({ menu }) => {
    if (isCustomTable) {
      return (
        <img
          src={
            menu.m_img
              ? SHOP_CUSTOM_IMG_PATH +
                shopInfo.table_name.replace("_", "") +
                // "/" +
                // shopInfo.s_idx +
                "/" +
                "menu/" +
                menu.m_img
              : menuDefaultImg
          }
          onError={defaultImgEvent}
          alt="메뉴이미지"
          loading="lazy"
        />
      );
    }
    return (
      <img
        src={menu.m_img ? MENU_IMG_PATH + menu.m_img : menuDefaultImg}
        onError={defaultImgEvent}
        alt="메뉴이미지"
      />
    );
  };
  const menuList = menuData.map((menu, index) => {
    //console.log('menu');
    //console.dir(menu);
    return (
      <>
        {menu.m_price <= 0 && menu.m_option_cate[0]?.m_options[0]?.mo_price ===
        menu.m_option_cate[0]?.m_options[
          menu.m_option_cate[0]?.m_options.length - 1
        ]?.mo_price ? (
          ""
        ) : (
          <li
            key={menu.m_idx + index}
            className="menu-article"
            data-midx={menu.m_idx}
            data-price={menu.m_price}
          >
            <div className="shop-menu-head">
              <div
                className="menu-info"
              //  onClick={() => {
                 // if (isCustomTable)
                    //if (shopInfo.c2_idx == 20) {
                      // 영화관 상품 디테일 추가예정
                      // navigate("movieDetail", {
                      //   state: { shopInfo: shopInfo, menu: menu },
                      // });
                   // } else {
                    //  navigate("detail", {
                     //   state: { shopInfo: shopInfo, menu: menu },
                     // });
                  // }
              //  }}
              >
                <span className="menu-info-img">
                  <MenuImgComponents menu={menu} />
                  {menu.m_is_soldout > 0 && (
                    <p className="menu-preparing">품절</p>
                  )}
                </span>

                <span className="menu-name-box">
                  <p className="menu-name">{menu.m_name}</p>
                  {menu.m_price <= 0 ? (
                    menu.m_option_cate[0]?.m_options[0]?.mo_price ===
                    menu.m_option_cate[0]?.m_options[
                      menu.m_option_cate[0]?.m_options.length - 1
                    ]?.mo_price ? (
                      // <p>
                      //   {menu.m_option_cate[0]?.m_options[0]?.mo_price
                      //     .toString()
                      //     .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      //   원
                      // </p>
                      ""
                    ) : (
                      <p>
                        {menu.m_option_cate[0]?.m_options[0]?.mo_price
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                          " ~ " +
                          menu.m_option_cate[0]?.m_options[
                            menu.m_option_cate[0]?.m_options.length - 1
                          ]?.mo_price
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        원
                      </p>
                    )
                  ) : (
                    <p>
                      {menu.m_price
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      원
                    </p>
                  )}
                </span>
              </div>

              {menu.m_option_cate && menu.m_option_cate.length > 0 ? (
                <>
                  {menu.m_is_soldout == 0 && (
                    <span className="menu-function">
                      <input
                        type="checkbox"
                        id={"menu-check_" + menu.m_idx + index}
                        name="menu-check"
                        className="menu-checkbox"
                        onChange={optionToggleEvent}
                      />
                      <label
                        htmlFor={"menu-check_" + menu.m_idx + index}
                      ></label>
                    </span>
                  )}
                </>
              ) : (
                <>
                  {menu.m_is_soldout == 0 && (
                    <span className="item-count-box">
                      <div className="count">
                        <button className="count-btn" onClick={minusClickEvent}>
                          -
                        </button>
                        <p className="count-num">0</p>
                        <button className="count-btn" onClick={plusClickEvent}>
                          +
                        </button>
                      </div>
                    </span>
                  )}
                </>
              )}
            </div>

            {menu.m_detail === "" || menu.m_detail === null ? (
              <span className="menu-ing-img-none">{menu.m_detail}</span>
            ) : (
              <span className="menu-note">{menu.m_detail}</span>
            )}

            {/* 체크박스상태에따라 숨기기/보이기 */}
            {/* 분기 3. menuOptionSection */}
            <MenuOptionSection
              menuOptionData={menu.m_option_cate}
              isCustomTable={isCustomTable}
            />
            {/* 반복구간 */}
          </li>
        )}
      </>
    );
  });
  if (menuData.length > 0) {
    if (menuData[0].m_is_soldout > 0) {
    }
  }
  return <ul className="shop-menu-check-list">{menuList}</ul>;
};

// 1. 메뉴 카테고리 섹션
const MenuCateSection = ({
  shopInfo,
  menuCateData,
  isCustomTable,
  setNavNumber,
}) => {
  const menuCateList = menuCateData.map((menuCate, index) => (
    <MenuCate
      menuCate={menuCate}
      index={index}
      setNavNumber={setNavNumber}
      shopInfo={shopInfo}
      isCustomTable={isCustomTable}
    />
  ));
  return menuCateList;
};

const MenuCate = ({
  menuCate,
  index,
  setNavNumber,
  shopInfo,
  isCustomTable,
}) => {
  const navState = {
    navNumber: menuCate.mc_idx,
    navScroll: 0,
  };
  const [isRef] = useThemeObserver(setNavNumber, navState);
  return (
    <div className="menu-type-check" key={menuCate.mc_idx + index} ref={isRef}>
      <div className="menu-type-check-content">
        {shopInfo.c3_idx == SHOP_C3_IDX_MAP["hair"] ? (
          <Beautydesigner menuCate={menuCate} />
        ) : (
          <span className="order-title" id={menuCate.mc_idx}>
            {menuCate.mc_name}
          </span>
        )}

        <MenuSection
          shopInfo={shopInfo}
          menuData={menuCate.mc_menus}
          isCustomTable={isCustomTable}
        />
      </div>
    </div>
  );
};

const ShopMenuFood = ({
  shopInfo,
  menuList,
  menuRef,
  isCustomTable,
  setNavNumber,
}) => {
  return (
    <section className="shop-menu-select" ref={menuRef}>
      {Object.keys(shopInfo).length > 0 && (
        <>
          <MenuCateSection
            shopInfo={shopInfo}
            menuCateData={menuList}
            isCustomTable={isCustomTable}
            setNavNumber={setNavNumber}
          />
          <div className="origin-note">
            <div className="origin-note-text">
              {shopInfo?.c2_idx != 20 && shopInfo?.c2_idx != 21 && (
                <p>특이사항</p>
              )}
              <p>{shopInfo.s_origin}</p>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default ShopMenuFood;
