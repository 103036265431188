import KakaoMap from "components/common/KakaoMap";
import KakaoNavi from "components/common/KakaoNavi";
import "components/order/style/ShopInfo.css";

// 지호 220706 상점정보보기
const Shopinfo = ({ shopInfo }) => {
  const ShopInfoRestDay_do = ({ rest_day, days, index }) => {
    let day = ["일", "월", "화", "수", "목", "금", "토"];
    return (
      <li key={index}>
        <span className="do-open">{day[days]}</span>
        <span>{rest_day.d_open_time + " ~ " + rest_day.d_close_time}</span>
      </li>
    );
  };

  const ShopInfoRestDay_not = ({ rest_day, days, index }) => {
    let day = ["일", "월", "화", "수", "목", "금", "토"];
    return (
      <li key={index}>
        <span className="not-open">{day[days]}</span>
        <span>-</span>
      </li>
    );
  };

  const RestDay = ({ rest_day }) => {
    let rest_day_all = rest_day.map((days, index) => {
      if (days.d_rest == "1") {
        return <ShopInfoRestDay_do key={index} rest_day={days} days={index} />;
      } else if (days.d_rest == "0") {
        return <ShopInfoRestDay_not key={index} rest_day={days} days={index} />;
      }
    });
    let sun_day = rest_day_all.shift();
    rest_day_all.push(sun_day);
    return rest_day_all;
  };
  // 주석이다
  const TipPrice = ({ tip_price }) => {
    let tip_cnt = 0;
    let tip_arr = tip_price.map((price, index) => {
      tip_cnt++;
      if (tip_cnt < tip_price.length) {
        return (
          <li key={index}>
            <p>
              {price.t_price === tip_price[tip_cnt].t_price
                ? price.t_std_price
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                "원 ~ " +
                tip_price[tip_cnt].t_std_price
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                "원 미만"
                : price.t_std_price
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원 미만"}
            </p>
            <p>
              {"+" +
                price.t_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                "원"}
            </p>
          </li>
        );
      } else {
        return (
          <li key={index}>
            <p>
              {price.t_std_price
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              원 이상
            </p>
            <p>
              {"+" +
                price.t_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                "원"}
            </p>
          </li>
        );
      }
    });
    return tip_arr;
  };

  function bizNoFormatter(num) {
    var formatNum = "";
    try {
      if (num.length == 10) {
        formatNum = num.replace(/(\d{3})(\d{2})(\d{5})/, "$1-$2-$3");
      }
    } catch (e) {
      formatNum = num;
      console.error(e);
    }
    return formatNum;
  }

  // 지호 220707 배송비
  const DelTipPrice = ({ tipPrice }) => {
    let tip_cnt = 0;
    let tip_price = [];
    let dong_name = [];

    for (let i = 0; i < tipPrice.length; i++) {
      tip_price.push(tipPrice[i].t_price);
    }

    let tip_result = tip_price.filter((v, i) => tip_price.indexOf(v) === i);

    for (let j = 0; j < tip_result.length; j++) {
      let dong_nm = [];
      for (let k = 0; k < tipPrice.length; k++) {
        if (tip_result[j] == tipPrice[k].t_price) {
          dong_nm.push(tipPrice[k].dong_name);
        }
      }
      dong_name.push(dong_nm);
    }

    const targetComponentList = dong_name.map((dong, index) => {
      return (
        <li key={index}>
          <span>{dong_name[index] + " "}</span>
          <span>
            {"+" +
              tip_result[index]
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
              "원"}
          </span>
        </li>
      );
    });

    return targetComponentList;
  };

  return (
    <section className="shop-info-frame">
      <div className="shop-map-wrap">
        <div className="shop-map">
          <span className="order-title">매장정보</span>
      
          <KakaoMap
            lat={shopInfo.lat}
            lng={shopInfo.lon}
            userLocation={false} />
        </div>
        <div className="shop-info-detail">
          <ul className="basic-info">
            <li>
              <span>주소</span>
              <span>{shopInfo.s_addr}</span>
            </li>
            <li>
              <span>주문가능</span>
              <span>
                {shopInfo.s_is_delivery > 0 && "배송 "}
                {shopInfo.s_is_packing > 0 && "포장 "}
                {shopInfo.s_is_parcel > 0 && "택배 "}
              </span>
            </li>
          </ul>
          <div className="opening-hours">
            <span>운영시간</span>
            <ul className="opening-date">
              {/* 호출 */}
              <RestDay rest_day={shopInfo.rest_rows} />
            </ul>
          </div>
        </div>
      </div>
      <div className="delivery-tip">
        <span className="order-title">배송 팁</span>
        <span className="price-class">
          <ul className="tip-price-list">
            <span className="tip-price-list-text">금액별</span>
            <TipPrice tip_price={shopInfo.tip_price} />
          </ul>
        </span>

        <ul className="location-class">
          <span className="tip-price-list-text">지역별</span>

          <DelTipPrice tipPrice={shopInfo.tip_location} />
        </ul>

        <ul className="night-delivery">
          <span className="tip-price-list-text">야간 배송</span>
          <li className="tip-price-list-night">
            {shopInfo.s_night_price == "0" ? (
              <>
                <span></span>
                <span>
                  {"+" +
                    shopInfo.s_night_price
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                    "원"}
                </span>
              </>
            ) : (
              <>
                <span>
                  {shopInfo.s_night_start_time +
                    " ~ " +
                    shopInfo.s_night_end_time}
                </span>
                <span>
                  {"+" +
                    shopInfo.s_night_price
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                    "원"}
                </span>
              </>
            )}
          </li>
        </ul>
      </div>
      <div className="ceo-info-wrap">
        <div className="ceo-info">
          <span className="order-title">사업자정보</span>
          <table className="ceo-info-table">
            <tbody>
              <tr>
                <td>대표자명</td>
                <td>{shopInfo.s_ceo_name}</td>
              </tr>
              <tr>
                <td>상호명</td>
                <td>{shopInfo.s_name}</td>
              </tr>
              <tr>
                <td>사업자주소</td>
                <td>{shopInfo.s_addr}</td>
              </tr>
              <tr>
                <td>사업자번호</td>
                <td>{bizNoFormatter(shopInfo.s_biz_num)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default Shopinfo;
