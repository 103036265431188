import { useEffect, useRef, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";

import { getBasket, getCateList, selectPopKeyword } from "service/OrderService";

import ADBanner from "components/main/ADBannerComponent";
import Shop from "components/order/ShopComponent";
import ShopComponentJustInfo from "components/order/ShopComponentJustInfo";
import ShopList from "components/order/ShopListComponent";
import ShopCategory from "components/order/ShopCategoryComponent";
import OrderConfirm from "components/order/OrderConfirmComponent";
import Payment from "components/order/Payment";
import OrderWaiting from "components/order/OrderWaiting";
import LocationSet from "components/common/LocationSet";
import ShopComponentJangboja from "components/order/ShopComponentJangboja";
import ProductDetail from "components/order/product/ProductDetail";
import InputPayment from "components/order/InputPayment";
import InputOrderConfirm from "components/order/InputOrderConfirmComponent";


import "components/order/style/Ordering.css";
import Header from "components/common/Header";
import ShopSearchComponent from "../components/order/ShopSearchComponent";
import stateUtilityInstance from "../TestClass";
import { serverErrorHandler } from "App-config";

const { goPay } = window;

const OrderRouter = ({
  isKiikeyApp,
  IS_IOS,
  IS_ANDROID,
  isMobile,
  isLogin,
  setIsLogin,
  user,
  playVideoEvent,
  closePopupEvent,
  closeGamePopupEvent,
  hiddenCatchGame,
}) => {
  const navigate = useNavigate();

  const params = useParams();

  const menuRef = useRef();

  const [userInfo, setUserInfo] = useState({});

  const [basketCount, setBasketCount] = useState(0);
  const [basketList, setBasketList] = useState({ sIdx: 0, rows: [] });

  const [totalAmt, setTotalAmt] = useState(0);

  const [cateList, setCateList] = useState([]);
  const [searchKeyList, setSearchKeyList] = useState([]);

  const [shopInfo, setShopInfo] = useState({});

  const [title, setTitle] = useState("");

  const categoryClickEvent = ({ data, c3data }) => {
    //console.log('data');
    console.dir(c3data);
    if (isKiikeyApp) {
      if (IS_IOS) {
        //window.webkit.messageHandlers.scriptHandler.postMessage(message);
      }
      if (IS_ANDROID) {
        // 안드로이드
        window.KiikeyWebView.hideNavBar(true);
      }
    }

    let table_name = "";
    if (data.table_name) {
      table_name = "_" + data.table_name;
      console.log(table_name);
    }
    //setTitle(data.c3_name);
    stateUtilityInstance.setPropertyValue("selectedCateC3Index", data.c3_idx);
    console.log(
      "setName:" + stateUtilityInstance.getPropertyByName("selectedCateC3Index")
    );
    navigate("/order/search", {
      state: {
        title: data.c3_name,
        c3idx: data.c3_idx,
        c3_list: c3data,
        searchKey: "",
        is_product: data.used_product,
        table_name: table_name,
        brandCode: data.brandCode,
      },
    });
  };

  useEffect(() => {
    // 뒤로가기로 페이지이동시 광고닫기
    closePopupEvent();

    selectPopKeyword()
      .then((res) => {
        if (res.success) {
          setSearchKeyList(res.data);
        }
      })
      .catch(console.error);

    getCateList()
      .then((res) => {
        if (res.data?.length > 0) {
          setCateList(res.data);
        }
      })
      .catch((err) => {
        console.error("cateList err", err);
        sessionStorage.clear();
        setIsLogin(false);
        serverErrorHandler(err);
      });

    basketReload();
  }, []);

  const basketReload = () => {
    getBasket()
      .then((res) => {
        if (res.success && res.data.length >= 0) {
          let bkCnt = 0;
          res.data.forEach((ele) => {
            ele.rows.forEach((row) => {
              bkCnt++;
            });
          });
          console.log("바스켓 카운트 :" + bkCnt);
          console.dir(res.data);

          if (bkCnt == 0) {
            stateUtilityInstance.setPropertyValue("basketShopIdx", 0);
          } else {
            stateUtilityInstance.setPropertyValue(
              "basketShopIdx",
              res.data[0].s_idx
            );
          }
          setBasketCount(bkCnt);
        }
      })
      .catch((err) => {
        console.error(err);
        serverErrorHandler(err);
      });
  };

  return (
    <>
      {!isMobile && (
        <ADBanner
          isLogin={isLogin}
          user={user}
          playVideoEvent={playVideoEvent}
          closeGamePopupEvent={closeGamePopupEvent}
          hiddenCatchGame={hiddenCatchGame}
        />
      )}

      <section className="ordering">
        {isMobile && <Header />}
        {isMobile && !params["*"] && (
          <ShopCategory
            isMobile={isMobile}
            categoryClickEvent={categoryClickEvent}
            cateList={cateList}
            searchKeyList={searchKeyList}
            isKiikeyApp={isKiikeyApp}
            IS_IOS={IS_IOS}
            IS_ANDROID={IS_ANDROID}
            setTitle={setTitle}
          />
        )}
        {!isMobile && (
          <ShopCategory
            isMobile={isMobile}
            categoryClickEvent={categoryClickEvent}
            cateList={cateList}
            searchKeyList={searchKeyList}
          />
        )}
        <Routes>
          <Route
            path=""
            element={
              !isMobile && (
                <Navigate
                  to="search"
                  state={{ title: "치킨", c3idx: 98, searchKey: "" }}
                />
              )
            }
          />
          <Route
            path="search"
            element={
              <ShopList
                isMobile={isMobile}
                setIsLogin={setIsLogin}
                IS_IOS={IS_IOS}
                IS_ANDROID={IS_ANDROID}
                isKiikeyApp={isKiikeyApp}
                setTitle={setTitle}
              />
            }
          />
          <Route
            path="ShopSearch"
            element={
              <ShopSearchComponent
              //isFocus = {isFocus}
              //setIsFocus = {setIsFocus}
              />
            }
          />
          <Route
            path="shop/*"
            element={
              <Shop
                isMobile={isMobile}
                basketList={basketList}
                setBasketList={setBasketList}
                totalAmt={totalAmt}
                setTotalAmt={setTotalAmt}
                shopInfo={shopInfo}
                setShopInfo={setShopInfo}
                menuRef={menuRef}
                basketCount={basketCount}
                basketReload={basketReload}
              />
            }
          />

          <Route
            path="jbg"
            element={
              <ShopComponentJangboja
                isMobile={isMobile}
                shopInfo={shopInfo}
                setShopInfo={setShopInfo}
                basketList={basketList}
                setBasketList={setBasketList}
                totalAmt={totalAmt}
                setTotalAmt={setTotalAmt}
              />
            }
          />

          <Route
            path="print"
            element={
              <ShopComponentJustInfo
                isMobile={isMobile}
                shopInfo={shopInfo}
                setShopInfo={setShopInfo}
                basketList={basketList}
                setBasketList={setBasketList}
                totalAmt={totalAmt}
                setTotalAmt={setTotalAmt}
              />
            }
          />

          <Route
            path="prod"
            element={
              <ProductDetail
                basketCount={basketCount}
                basketReload={basketReload}
              />
            }
          />

          <Route
            path="confirm"
            element={
              <OrderConfirm
                basketReload={basketReload}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
              />
            }
          />

          <Route
            path="inputConfirm"
            element={
              <InputOrderConfirm
                basketReload={basketReload}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
              />
            }
          />

          <Route
            path="payment"
            element={
              <Payment
                isMobile={isMobile}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
              />
            }
          />
          <Route
            path="inputPayment"
            element={
              <InputPayment
                isMobile={isMobile}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
              />
            }
          />
          <Route
            path="payment/changeLocation"
            element={
              <LocationSet
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                redirectUrl={"/order/payment"}
              />
            }
          />


          <Route path="payresult" element={<OrderWaiting />} />
        </Routes>
      </section>
    </>
  );
};

export default OrderRouter;
