import StarCnt from "components/common/StarCountComponent";

import "components/order/style/ShopMenuGrade.css";

const ShopGrade = ({ starData, totalCnt }) => {
  let avg = 0;
  if (starData) {
    avg =
      Math.round(
        ((starData["5"] * 5 +
          starData["4"] * 4 +
          starData["3"] * 3 +
          starData["2"] * 2 +
          starData["1"] * 1 +
          starData["0"]) /
          totalCnt) *
          2
      ) / 2;
  }

  return (
    <div className="shop-grade-frame">
      <div className="grade-number-box">
        <span className="grade-number">{isNaN(avg) ? 0 : avg.toFixed(1)}</span>
        <span className="grade-star">
          <StarCnt isAvg={true} avg={avg} />
        </span>
      </div>
      <div className="grade-bar-box">
        <ul className="grade-bar">
          <li>
            <span>5점</span>
            <progress
              value={starData && starData["5"]}
              max={totalCnt}
              className="progress-bar"
            ></progress>
            <span>{starData && starData["5"]}</span>
          </li>
          <li>
            <span>4점</span>
            <progress
              value={starData && starData["4"]}
              max={totalCnt}
              className="progress-bar"
            ></progress>
            <span>{starData && starData["4"]}</span>
          </li>
          <li>
            <span>3점</span>
            <progress
              value={starData && starData["3"]}
              max={totalCnt}
              className="progress-bar"
            ></progress>
            <span>{starData && starData["3"]}</span>
          </li>
          <li>
            <span>2점</span>
            <progress
              value={starData && starData["2"]}
              max={totalCnt}
              className="progress-bar"
            ></progress>
            <span>{starData && starData["2"]}</span>
          </li>
          <li>
            <span>1점</span>
            <progress
              value={starData && starData["1"]}
              max={totalCnt}
              className="progress-bar"
            ></progress>
            <span>{starData && starData["1"]}</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ShopGrade;
