import "components/order/style/ShopSearchComponent.css";
import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import datetime from "src_assets/date-time_b.png";
import stateUtilityInstance from "../../TestClass";

const ShopSearchComponent = ({ searchKeyList }) => {
  const { state } = useLocation();
  console.log("state");
  console.dir(state);
  const navigate = useNavigate();

  searchKeyList = state?.searchKeyList;
  //setHashTagList(searchKeyList);
  const [nowDate, setNowDate] = useState();
  const isRef = useRef(null);

  const [isSearchFocus, setIsSearchFocus] = useState(false);

  const [hashTagList, setHashTagList] = useState([]);

  const hashTagAddToLocalStorage = (searchKey) => {
    const searchHashTagList = localStorage.getItem("searchHashTagList");
    let newSearchHashTagList = [];
    if (searchHashTagList) {
      newSearchHashTagList = searchHashTagList.split(",");
    } else {
      localStorage.setItem("searchHashTagList", []);
    }

    if (searchKey) {
      newSearchHashTagList.unshift(searchKey);
      newSearchHashTagList = new Set(newSearchHashTagList);
      newSearchHashTagList = [...newSearchHashTagList];
      // if (newSearchHashTagList.length > 4) {
      //   // 최대 4개
      //   newSearchHashTagList.pop();
      // }
    }

    localStorage.setItem("searchHashTagList", newSearchHashTagList);
    setHashTagList(newSearchHashTagList);
  };

  const hashTagDeleteToLocalStorage = (index) => {
    const searchHashTagList = localStorage.getItem("searchHashTagList");
    let newSearchHashTagList = searchHashTagList.split(",");
    newSearchHashTagList.splice(index, 1);
    //newSearchHashTagList = [...newSearchHashTagList];

    localStorage.setItem("searchHashTagList", newSearchHashTagList);
    setHashTagList(newSearchHashTagList);
  };

  const hashTagDeleteAllToLocalStorage = () => {
    localStorage.removeItem("searchHashTagList")
    setHashTagList([]);
  };

  const gotoSearchKey = (searchKey) => {
    hashTagAddToLocalStorage(searchKey);
    navigate("/order/search", {
      state: {
        title: "검색 결과",
        searchKey: searchKey,
        c3_list: state.c3_list,
      },
    });
  };

  const searchOnChangeEvent = (e) => {
    if (e.keyCode == "13") {
      gotoSearchKey(e.target.value);
    } else if (e.type == "click") {
      gotoSearchKey(document.getElementById("order-search").value);
    }
  };

  useEffect(() => {
    stateUtilityInstance.setMobileHeaderTitle("검색");
    hashTagAddToLocalStorage();
  }, []);

  useEffect(() => {
    const now = new Date();
    setNowDate(
      `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(now.getDate()).padStart(2, "0")}`
    );
  }, [searchKeyList]);

  useEffect(() => {
    if (isRef) isRef.current.focus();
  }, [isRef]);

  const searchKeyComponents = searchKeyList.map((item, index) => {
    return (
      <li
        key={index}
        onClick={() => {
          gotoSearchKey(item.pk_keyword);
        }}
      >
        <span className="list-number">{index + 1}.</span>
        <span className="pop-name">{item.pk_keyword}</span>
      </li>
    );
  });

  const hashTagListComponents = hashTagList.slice(0, 4).map((item, index) => {
    return (
      <li
        key={index}
        onClick={() => {
          gotoSearchKey(item);
        }}
      >
        #{item}
      </li>
    );
  });

  return (
    <section className="search-order-menu-list-frame">
      <div className="search-order-search-wrap">
        <div className="search-order-search-box">
          
          <input
          style={{paddingLeft : '20px'}}
            id="order-search1"
            placeholder="이쁨에서 검색하세요."
            onKeyUp={searchOnChangeEvent}
            onFocus={() => {
              setIsSearchFocus(true);
            }}
            onBlur={() => {
              setTimeout(() => {
                setIsSearchFocus(false);
              }, 200);
            }}
            ref={isRef}
            type="text"
          />

          <button
            className="search-order-search-button"
            onClick={() =>
              gotoSearchKey(document.getElementById("order-search1").value)
            }
            /* onSubmit={searchOnChangeEvent} */
          />
        </div>
        
        {hashTagList.length > 0 && (
          <div className="search-popular-menu-box">
            <ul>
              {/* {hashTagList.map((item, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => {
                        gotoSearchKey(item);
                      }}
                    >
                      #{item}
                    </li>
                  );
              })} */}

              {hashTagListComponents}
            </ul>
          </div>
          
        )}
      </div>
      <div className="search-popular-result">
        <div className="search-popular-name">
          <span className="search-popular-name-text">인기검색어</span>
        </div>
        <ul className="search-popular-list">
          {/*인기  검색어 리스트 자리 */}
          {searchKeyComponents}
        </ul>
        <div className="search-popular-date-box">
          <span className="search-popular-date-img">
            <img src={datetime} alt="시간이미지" />
          </span>
          <span className="search-popular-date"> {nowDate}</span>
        </div>
      </div>

      <div className="search-last-list">
        <div className="search-last-list-name">
          <span>최근검색어</span>
          <button onClick={() => {
            hashTagDeleteAllToLocalStorage();
          }}>전체삭제</button>
        </div>
        {hashTagList.length > 0 && (
          <ul className="search-last-list-list">
            {hashTagList.map((item, index) => {
              return (
                <li>
                  <div
                    key={index}
                    onClick={() => {
                      gotoSearchKey(item);
                    }}
                  >
                    {item}
                  </div>

                  <button
                    onClick={() => {
                      hashTagDeleteToLocalStorage(index);
                    }}
                  ></button>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </section>
  );
};

export default ShopSearchComponent;
