import { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// import { messaging } from "../firebase";

import { isKiikeyApp, IS_ANDROID, IS_IOS, serverErrorHandler, USER_DTO } from "App-config";
import {
  getPopupNoticeList,
  loginCheck,
  signout,
} from "service/AccountService";
import { getAD } from "service/ADSService";
import { getGame } from "service/ADSService";

import MainRouter from "router/MainRouter";
import OrderRouter from "router/OrderRouter";
import MykiikeyRouter from "router/MykiikeyRouter";
import AccountRouter from "router/AccountRouter";

import HeaderComponent from "components/common/HeaderComponent";
import AboutComponent from "components/about/AboutComponent";
import GameComponent from "components/main/AppGameComponent";
import FooterComponent from "components/common/FooterComponent";
import UserAgree from "components/common/UserAgree";
import PersonalInfo from "components/common/PersonalInfo";
import EventModal from "components/common/EventModal";
import VideoPopup from "components/main/VideoPopupComponent";
import GamePopup from "components/main/GamePopupComponent";
import AppShare from "components/main/AppShare";
import Loading from "components/common/Loading";
import EmailRefused from "components/common/EmailRefused";
import NotFoundPage from "components/common/NotFoundPage";
import { NewLoading } from "components/common/NewLoading";

import ShopSearchComponent from "components/order/ShopSearchComponent";

// function requestPermission() {
//   console.log("Requesting permission...");
//   Notification.requestPermission().then((permission) => {
//     if (permission === "granted") {
//       console.log("Notification permission granted.");
//       //허가를 요청합니다!
//       console.log("messaging :", messaging);
//     }
//   });
// }

// requestPermission();

const AppRouter = () => {
  const isMobile = useMediaQuery({ maxWidth: "768px" });

  const [user, setUser] = useState(USER_DTO);

  const [popupDataList, setPopupDataList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  const [isRunning, setIsRunning] = useState(0);
  const [isNotQuiz, setIsNotQuiz] = useState(false);

  const [isGameRunning, setGameIsRunning] = useState(0);
  const [isWebView, setIsWebView] = useState(false);

  const [isMain, setIsMain] = useState(false);

  const [adData, setAD] = useState({});
  const [hcgData, setHCG] = useState({});

  const [isRight, setIsRight] = useState(false);

  const logoutEvent = () => {
    signout().then((res) => {
      localStorage.clear();
      sessionStorage.clear();
      setIsLogin(false);
      setUser(USER_DTO);
    });
  };

  const closePopupEvent = (status) => {
    setIsLoading(true);
    if (status) {
      setIsRunning(status);
    } else {
      setIsRunning(0);
    }
    setIsLoading(false);
  };

  const playVideoEvent = (e) => {
    if (!isLogin) {
      alert("로그인 후 이용해주세요.");
      sessionStorage.clear();
      setIsLogin(false);
      window.location.href = "/account/signin";
      return;
    }
    getAD(e.currentTarget.value)
      .then((res) => {
        if (res.data?.length < 1) {
          alert("상영중인 광고를 전부 시청하셨습니다.");
        } else {
          setAD(res.data[0]);
          setIsRunning(1);
        }
      })
      .catch((err) => {
        alert(err.msg);
        setIsLogin(false);

        serverErrorHandler(err);
      });
  };

  const scriptHandler = (event, message) => {
    if (message == "closeGame") {
      // event.preventDefault();
      try {
        if (IS_IOS) {
          window.webkit.messageHandlers.scriptHandler.postMessage(message);
        }
        if (IS_ANDROID) {
          // 안드로이드
          try {
            window.KiikeyWebView.gameEnded();
          } catch (error) {
            window.gameState.gameEnded();
          }
        }
      } catch (error) {
        alert(error);
      }
    }
  };

  const hiddenCatchGame = (e, status) => {
    if (!isKiikeyApp) {
      if (!isLogin) {
        alert("로그인 후 이용해주세요.");
        sessionStorage.clear();
        setIsLogin(false);
        window.location.href = "/account/signin";
        return;
      }
    }
    getGame(e?.currentTarget.value)
      .then((res) => {
        if (res.data?.length < 1) {
          if (isKiikeyApp) {
            alert("플레이 가능한 게임이 없습니다.");
            scriptHandler(e, "closeGame");
          } else {
            alert("플레이 가능한 게임이 없습니다.");
          }
        } else {
          setHCG(res.data[0]);
          if (status === "app") {
            setIsWebView(true);
          }
          setGameIsRunning(1);
        }
      })
      .catch((err) => {
        alert(err.msg);

        setIsLogin(false);
        serverErrorHandler(err);
      });
  };

  const closeGamePopupEvent = (e, status) => {
    if ((e && e == "closeBtn") || (e && e.target.dataset?.title == "close")) {
      setGameIsRunning(0);
    }

    setIsLoading(true);
    if (status) {
      setGameIsRunning(status);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const notPopupNoticeList = localStorage.getItem("notPopupNoticeList");
    if (!notPopupNoticeList) {
      localStorage.setItem("notPopupNoticeList", []);
    }
    getPopupNoticeList()
      .then((res) => {
        if (res.success) {
          if (res.data.length > 0) {
            /**
             * 2022-07-17 김재용
             * 팝업의 다시보지않기 기능추가, 로컬스토리지를 활용함
             */
            const notPopupNoticeList =
              localStorage.getItem("notPopupNoticeList");
            let newNotPopupNoticeList = [];
            if (notPopupNoticeList) {
              newNotPopupNoticeList = notPopupNoticeList.split(",");
            }

            let popupList = [];
            for (let i = 0; i < res.data.length; i++) {
              const dbdata = res.data[i];
              let flag = true;
              for (let j = 0; j < newNotPopupNoticeList.length; j++) {
                const ele = newNotPopupNoticeList[j];
                if (dbdata.pn_idx == ele) {
                  flag = false;
                  break;
                }
              }
              if (flag) {
                popupList.push(dbdata);
              }
            }

            if (popupList.length > 0) {
              setPopupDataList(popupList);
            }
          }
        }
      })
      .catch(console.error);
    // 로그인체크
    loginCheck()
      .then((res) => {
        if (res.success) {
          setIsLogin(true);
          setUser(res.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const eventModalCloseEvent = (e, data) => {
    /**
     * 2022-07-17 김재용
     * 팝업의 다시보지않기 기능추가, 로컬스토리지를 활용함
     */
    const action = e.target.dataset.title;
    if (action === "exit") {
      const notPopupNoticeList = localStorage.getItem("notPopupNoticeList");
      let newNotPopupNoticeList = [];
      if (notPopupNoticeList) {
        newNotPopupNoticeList = notPopupNoticeList.split(",");
      }
      newNotPopupNoticeList.push(data.pn_idx);

      newNotPopupNoticeList = new Set(newNotPopupNoticeList);
      newNotPopupNoticeList = [...newNotPopupNoticeList];
      localStorage.setItem("notPopupNoticeList", newNotPopupNoticeList);
    } else if (action === "img") {
      // 이벤트 페이지 이동
    } else {
    }
    setPopupDataList([]);
  };

  return (
    <BrowserRouter>
      {/* {isRunning > 0 && (
        <section
          className={isRunning === 1 ? "popup-frame" : "bannermodal-frame"}
          onClick={closePopupEvent}
        >
          <VideoPopup
            isNotQuiz={isNotQuiz}
            setIsNotQuiz={setIsNotQuiz}
            adData={adData}
            isRunning={isRunning}
            closePopupEvent={closePopupEvent}
            setUser={setUser}
            setIsRight={setIsRight}
            isRight={isRight}
          />
        </section>
      )} */}
      
      {/* {isGameRunning > 0 && !isWebView && (
        <section
          className={"popup-frame"}
          data-title="close"
          onClick={closeGamePopupEvent}
        >
          <GamePopup
            isMobile={isMobile}
            setIsNotQuiz={setIsNotQuiz}
            hcgData={hcgData}
            isGameRunning={isGameRunning}
            closeGamePopupEvent={closeGamePopupEvent}
            setUser={setUser}
            setIsRight={setIsRight}
            isRight={isRight}
            isApp={isWebView}
            hiddenCatchGame={hiddenCatchGame}
            scriptHandler={scriptHandler}
          />
        </section>
      )}
     
      {isGameRunning > 0 && isWebView && (
        <section
          className={"popup-frame"}
          data-title="webViewClose"
          onClick={closeGamePopupEvent}
        >
          <GamePopup
            isMobile={isMobile}
            setIsNotQuiz={setIsNotQuiz}
            hcgData={hcgData}
            isGameRunning={isGameRunning}
            closeGamePopupEvent={closeGamePopupEvent}
            setUser={setUser}
            setIsRight={setIsRight}
            isRight={isRight}
            isApp={isWebView}
            hiddenCatchGame={hiddenCatchGame}
            scriptHandler={scriptHandler}
          />
        </section>
      )} */}

      {/* {isMain && popupDataList.length > 0 && (
        <EventModal
          popupDataList={popupDataList}
          eventModalCloseEvent={eventModalCloseEvent}
        />
      )} */}
      {/* {isLoading ? <Loading /> : ""} */}
      {/* {!isKiikeyApp && (
        <HeaderComponent
          isLogin={isLogin}
          isMobile={isMobile}
          logoutEvent={logoutEvent}
        />
      )} */}
      <Routes>
        {/* <Route
          path=""
          element={
            <MainRouter
              isRunning={isRunning}
              setIsMain={setIsMain}
              user={user}
              setUser={setUser}
              isLogin={isLogin}
              setIsLogin={setIsLogin}
              playVideoEvent={playVideoEvent}
              hiddenCatchGame={hiddenCatchGame}
              isMobile={isMobile}
            />
          }
        /> */}
        <Route
          path="order/*"
          element={
            <OrderRouter
              isKiikeyApp={isKiikeyApp}
              IS_IOS={IS_IOS}
              IS_ANDROID={IS_ANDROID}
              user={user}
              isMobile={isMobile}
              isLogin={isLogin}
              setIsLogin={setIsLogin}
              playVideoEvent={playVideoEvent}
              closePopupEvent={closePopupEvent}
              closeGamePopupEvent={closeGamePopupEvent}
              hiddenCatchGame={hiddenCatchGame}
            />
          }
        />
        <Route
          path="game"
          element={
            
              <section
                className={"popup-frame"}
                data-title="webViewClose"
                onClick={closeGamePopupEvent}
              >
                <GamePopup
                  isMobile={isMobile}
                  setIsNotQuiz={setIsNotQuiz}
                  hcgData={hcgData}
                  isGameRunning={isGameRunning}
                  closeGamePopupEvent={closeGamePopupEvent}
                  setUser={setUser}
                  setIsRight={setIsRight}
                  isRight={isRight}
                  isApp={isWebView}
                  hiddenCatchGame={hiddenCatchGame}
                  scriptHandler={scriptHandler}
                />
              </section>
            

          }
        />
        <Route path="order/search1" element={<NewLoading />} />
        <Route
          path="about"
          element={<AboutComponent closePopupEvent={closePopupEvent} />}
        />
        {isKiikeyApp && (
          <>
            <Route
              path="hiddenCatchGame"
              element={
                <GameComponent
                  isMobile={isMobile}
                  user={user}
                  setUser={setUser}
                  // hiddenCatchGame={hiddenCatchGame}
                  data={hcgData}
                />
              }
            />
            <Route path="share/:sIdx" element={<AppShare />} />
          </>
        )}
        <Route
          path="mykiki/*"
          element={
            <MykiikeyRouter
              user={user}
              setAD={setAD}
              isMobile={isMobile}
              isLogin={isLogin}
              setIsNotQuiz={setIsNotQuiz}
              setIsRunning={setIsRunning}
              playVideoEvent={playVideoEvent}
              closePopupEvent={closePopupEvent}
              closeGamePopupEvent={closeGamePopupEvent}
              hiddenCatchGame={hiddenCatchGame}
            />
          }
        />
        <Route
          path="account/*"
          element={
            <AccountRouter
              isLogin={isLogin}
              setIsLogin={setIsLogin}
              setUser={setUser}
              closePopupEvent={closePopupEvent}
            />
          }
        />
        <Route path="personalInfo" element={<PersonalInfo />} />
        <Route path="emailRefused" element={<EmailRefused />} />
        <Route path="userAgree" element={<UserAgree />} />
        <Route path="/*" element={<NotFoundPage />} />
      </Routes>
      {!isKiikeyApp && <FooterComponent />}
    </BrowserRouter>
  );
};

export default AppRouter;
