let backendHost;
let imageHost;
let partnerLoginUrl;

const hostname = window && window.location && window.location.hostname;
if (hostname.includes("joynjoin")) {
  backendHost = "https://test.joynjoin.kr/api";
  imageHost = "https://adtong.joynjoin.kr/img";
  partnerLoginUrl = "https://partner.joynjoin.kr/login.php";
} else {
  backendHost = `http://localhost:4000/api`;
  imageHost = `http://localhost:7001/kk_api/img`;
  partnerLoginUrl = `http://localhost:7002/login.php`;
}

export const API_BASE_URL = `${backendHost}`;
export const IMAGE_BASE_URL = `${imageHost}`;

export const PARTNER_LOGIN_URL = `${partnerLoginUrl}`;

export const isKiikeyApp = window.navigator.userAgent.includes("KIIKEY_");
export const IS_ANDROID = window.navigator.userAgent.includes("KIIKEY_ANDROID");
export const IS_IOS = window.navigator.userAgent.includes("KIIKEY_IOS");

export const RECAPTCHA_ID = "recaptcha-container";

export const AD_API_PATH = "/ads";
export const USER_API_PATH = "/user/account";
export const USER_WALLET_API_PATH = "/user/wallet";
export const USER_ORDER_API_PATH = "/user/order";
export const USER_SHOP_API_PATH = "/user/shop";
export const ORDER_SHOP_API_PATH = "/order/shop";
export const ORDER_JBG_SHOP_API_PATH = "/order/shopJbg";
export const ORDER_PAYREQUEST_API_PATH = "/order/payRequest";
export const ORDER_PAYRESULT_API_PATH = "/order/payResult";

export const SHOP_IMG_PATH = IMAGE_BASE_URL + "/shop/";
export const SHOP_CUSTOM_IMG_PATH = IMAGE_BASE_URL + "/";
export const SHOP_COSMETICS_IMG_PATH = IMAGE_BASE_URL + "/cosmetics/";
export const MENU_IMG_PATH = IMAGE_BASE_URL + "/menu/";
export const REVIEW_IMG_PATH = IMAGE_BASE_URL + "/review/";
export const POPUP_NOTICE_IMG_PATH = IMAGE_BASE_URL + "/notice/";
export const EVENT_SLAVE_IMG_PATH = IMAGE_BASE_URL + "/event/slave/";
export const EVENT_APPLY_IMG_PATH = IMAGE_BASE_URL + "/event/applyImg/";

export const PRINTER_API_PATH = "/printer/";

export const SHOP_C2_IDX_MAP = {
  beauty: 4,
  clothes: 15,
  movie: 20,
};

export const SHOP_C3_IDX_MAP = {
  cosmetics: 91,
  scalp: 92,
  makeup: 93,
  beauty: 110,
  nail: 111,
  tatto: 112,
  waxing: 113,
  aesthetics: 114,
  diet: 115,
  wedding: 116,
  hair: 90,
  jbg: 46,
};

// 특수문자
// eslint-disable-next-line
export const NOT_SPECIAL_CODE_REG = /[~!@#$%";'^,&*()_+|</>=>`?:{[\}]/g;
// 공백
export const NOT_SPACE_REG = /^\s+|\s+$/g;

export const USER_ID_MIN_LENGTH = 4;
export const USER_ID_MAX_LENGTH = 25;

export const USER_NAME_MIN_LENGTH = 2;
export const USER_NAME_MAX_LENGTH = 10;

export const USER_PASSWORD_MIN_LENGTH = 8;

export const USER_ID_REG = /^[a-zA-Z]+[a-zA-Z0-9]{3,24}$/g;
export const USER_NAME_REG = /^[가-힣a-zA-Z]$/g;

export const PHONE_REG = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
export const EIGHT_BIRTH_REG =
  /^(19[0-9][0-9]|20\d{2})(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/;

export const DEFAULT_GUEST_NAME = "솜솜";
export const DEFAULT_POINT_VALUE = "7700000";
export const DESKTOP_VIEW_COUNT = 6;
export const MOBILE_VIEW_COUNT = 4;
export const DEFAULT_POINT_PANNEL_VALUE = Array.from(DEFAULT_POINT_VALUE);
export const DEFAULT_POINT_PANNEL_INIT_VALUE = [
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
];
export const DEFAULT_POINT_PANNEL_LENGTH = DEFAULT_POINT_VALUE.length;

export const DEFAULT_ADS_VIEW_COUNT = 0;
export const DEFAULT_ADS_TOTAL_VIEW_COUNT = 30;

export const USER_DTO = {
  myPoint: DEFAULT_POINT_VALUE,
  mbName: DEFAULT_GUEST_NAME,
  viewCnt: DEFAULT_ADS_VIEW_COUNT,
  totalViewCnt: DEFAULT_ADS_TOTAL_VIEW_COUNT,
  isLogin: false,
  adList: [],
  gameList: [],
};

export const KAKAO_ADDR_API_URL =
  "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";

export const debounceFunction = (callback, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => callback(...args), delay);
  };
};

export const throttleFunction = (callback, delay) => {
  let timerId;
  return () => {
    if (timerId) return;
    timerId = setTimeout(() => {
      callback();
      timerId = null;
    }, delay);
  };
};

export const checkPlatform = (ua) => {
  if (ua === undefined) {
    ua = window.navigator.userAgent;
  }

  ua = ua.toLowerCase();
  var platform = {};
  var matched = {};
  var userPlatform = "pc";
  var platform_match =
    /(ipad)/.exec(ua) ||
    /(ipod)/.exec(ua) ||
    /(windows phone)/.exec(ua) ||
    /(iphone)/.exec(ua) ||
    /(kindle)/.exec(ua) ||
    /(silk)/.exec(ua) ||
    /(android)/.exec(ua) ||
    /(win)/.exec(ua) ||
    /(mac)/.exec(ua) ||
    /(linux)/.exec(ua) ||
    /(cros)/.exec(ua) ||
    /(playbook)/.exec(ua) ||
    /(bb)/.exec(ua) ||
    /(blackberry)/.exec(ua) ||
    [];

  matched.platform = platform_match[0] || "";

  if (matched.platform) {
    platform[matched.platform] = true;
  }

  if (
    platform.android ||
    platform.bb ||
    platform.blackberry ||
    platform.ipad ||
    platform.iphone ||
    platform.ipod ||
    platform.kindle ||
    platform.playbook ||
    platform.silk ||
    platform["windows phone"]
  ) {
    userPlatform = "mobile";
  }

  if (platform.cros || platform.mac || platform.linux || platform.win) {
    userPlatform = "pc";
  }

  return userPlatform;
};


export const serverErrorHandler = (err = null) => {
  sessionStorage.clear();

  if (isKiikeyApp) {
    if (err != null && err.data['code'] == "400") {
      if (IS_IOS) {
        window.webkit.messageHandlers.callBackHandler.postMessage({
          action: "loginCheckErr"
        });
      }
      if (IS_ANDROID) {
        window.KiikeyWebView.accessDeniedHandle();
      }
    } else {
      if (IS_IOS) {
        window.webkit.messageHandlers.callBackHandler.postMessage({
          action: "reSignIn"
        });
      }
      if (IS_ANDROID) {
        window.KiikeyWebView.reConnect();
      }
    }
  }
  else {
    window.location.href = "/account/signin";
  }
};

export const convertURLtoFile = async (url) => {

  // let options = {
  //   url: url,
  //   method: "GET",
  //   mode: "no-cors"
  // }
  const response = await fetch(url);
  const data = await response.blob();
  const ext = url.split(".").pop(); // url 구조에 맞게 수정할 것
  const filename = url.substring(url.lastIndexOf('/') + 1)// url 구조에 맞게 수정할 것
  const metadata = { type: `image/${ext}` };
  const file = new File([data], filename, metadata);
  return file;
};

window.NativeInterface = {
  historyBack: () => {
    // your javascript code
    window.history.back();
    return true;
  },
}