import { useState, useEffect } from "react";

import { getUserQnaList } from "service/AccountService";

import Pagination from "components/common/Pagination";

import "components/mykiikey/style/Mykiikey.css";
import "components/mykiikey/style/Servicecenter.css";
import stateUtilityInstance from "../../TestClass";

const Servicecenter = ({ userQnaList, setUserQnaList, isMobile }) => {
  const VIEW_LIMIT = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const offset = (currentPage - 1) * VIEW_LIMIT;
  const userQnaListLimit = isMobile
    ? userQnaList.slice(0, offset + VIEW_LIMIT)
    : userQnaList.slice(offset, offset + VIEW_LIMIT);

  const liTagList = userQnaListLimit.map((item, index) => {
    return (
      <li
        key={index}
        onClick={(e) => {
          e.currentTarget
            .querySelector(".questions-answer")
            .classList.toggle("dis-none");
        }}
      >
        <div className="questions-head">
          <div className="questions-text">
            <span>Q.{item.wr_subject}</span>
            <p>{item.wr_datetime.split(" ")[0]}</p>
          </div>
          <span className="questions-detail">
            <button className="open-answer-button" />
          </span>
        </div>
        {/* 접었다 펴는 것 .questions-answer / .questions-answer-none */}
        <div className="questions-answer dis-none">
          <span>{item.wr_content}</span>
        </div>
      </li>
    );
  });

  useEffect(() => {
    stateUtilityInstance.setMobileHeaderTitle('고객센터 Q&A');
    getUserQnaList()
      .then((res) => {
        if (res.success) {
          setUserQnaList(res.data);
        }
      })
      .catch(console.error);
  }, []);
  return (
    <div className="daps-frame">
      <ul className="questions-list">{liTagList}</ul>
      <Pagination
        isMobile={isMobile}
        limit={VIEW_LIMIT}
        offset={offset}
        type={"userQna"}
        totalCount={userQnaList.length}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default Servicecenter;
