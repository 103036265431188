import { useEffect, useState } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import { serverErrorHandler, SHOP_C2_IDX_MAP, SHOP_IMG_PATH } from "App-config";

import {
  getShopInfo,
  getShopMenuList,
  setBasket,
  delBasket,
} from "service/OrderService";

import FollowBasket from "components/order/FollowBasket";
import FollowBasketMobile from "components/order/FollowBasketMobile";
import StarCnt from "components/common/StarCountComponent";
import Beautydetail from "components/order/beautyPart/Beautydetail";
import ShopMenuInfo from "components/order/ShopMenuInfo";
import CateTypeBeautyDate from "components/order/categoryPart/CateTypeBeautyDate";

import timeImg from "src_assets/date-time_b.png";

import "components/order/style/MenuIntro.css";
import "components/order/style/ShopMenuList.css";
import "components/order/style/ShopMenuGrade.css";
import { useRef } from "react";
import { throttleFunction } from "../../App-config";
import stateUtilityInstance from "../../TestClass";
import { CycleBarLoading, NewLoading } from "components/common/NewLoading";
import ProductDetail from "./product/ProductDetail";

const Shop = ({
  isMobile,
  totalAmt,
  setTotalAmt,
  basketList,
  setBasketList,
  //shopInfo,
  //setShopInfo,
  menuRef,
  basketCount,
  basketReload,
}) => {
  const [navState, setNavNumber] = useState({
    navNumber: 0,
    navScroll: 0,
  });

  const navigate = useNavigate();

  const params = useParams();
  const { state } = useLocation();

  const calendarRef = useRef();

  const [selectDate, setSelectDate] = useState();

  const [menuList, setMenuList] = useState();
  const [shopInfo, setShopInfo] = useState();
  const [changeTab, setChangeTab] = useState(1);

  const [isLike, setIsLike] = useState(false);
  const [isSchedule, setIsSchedule] = useState(false);

  const [isCustomTable, setIsCustomTable] = useState(false);
  const [scroll_position_list, setScrollPostionList] = useState([]);

  //var scroll_position_list;
  var offset = window.innerHeight / 5;

  //console.log("offset : " + offset);
  const set_scroll_position_list = (data) => {
    console.dir(data);
    var scroll_position_list = data.map((value, index) => {
      var positon =
        window.scrollY +
        document.getElementById("" + value.mc_idx)?.getBoundingClientRect().top;
      //console.log(positon);

      // if(positon){
      return {
        id: value.mc_idx,
        y_pos: positon ? positon - offset : 0,
      };
      //}
    });

    console.dir(scroll_position_list);
    setScrollPostionList(scroll_position_list);
    //setScrollPostionList(list);
    //console.dir(scroll_position_list);
    // const position_list = {
    //   id : data.mc_idx,
    //   position : {data.}
    // }
  };

  const handleScroll = () => {
    var y = window.scrollY;
    //console.log('y좌표' + y);
    //console.log("offset : " + offset);

    let FocusBtn;
    var navSpan = document.getElementById("navScroll");
    for (let i = 0; i < scroll_position_list.length; i++) {
      let navBtn = document.getElementById(
        "navBtn" + scroll_position_list[i].id
      );

      if (scroll_position_list[i].y_pos == 0) {
        set_scroll_position_list(menuList);
        return;
      }

      //console.log('id:' + scroll_position_list[i].id);
      // if(!navBtn){
      //   //set_scroll_position_list(menuList);
      //   return;
      // }

      navBtn.style.backgroundColor = "#f2f2f2";
      //navBtn.style.backgroundColor = '#f2f2f2';
      // if(y < scroll_position_list[0].y_pos){
      //   navBtn.style.backgroundColor = '#f2f2f2';
      // }

      if (y > scroll_position_list[i].y_pos) {
        if (scroll_position_list[i + 1]) {
          if (y < scroll_position_list[i + 1].y_pos) {
            //console.log('paint');
            //navBtn.style.backgroundColor = 'red';

            // var x = navSpan.scrollLeft;

            // const navState = {
            //   navNumber: scroll_position_list[i].id,
            //   navScroll: x + navBtn.getBoundingClientRect().x - 50,
            // };

            // navBtn.style.backgroundColor = "var(--kk-color-Ymain)";

            // navSpan.scrollTo({
            //   left: navState.navScroll,
            //   //behavior:'smooth',
            // });

            FocusBtn = navBtn;

            //setNavNumber(navState);
            //return;
          } else {
            navBtn.style.backgroundColor = "#f2f2f2";
            navBtn.children[0].style.color = "#545454";
          }
        } else {
          //console.log('paint');
          //navBtn.style.backgroundColor = 'red';

          // var x = navSpan.scrollLeft;

          // const navState = {
          //   navNumber: scroll_position_list[i].id,
          //   navScroll: x + navBtn.getBoundingClientRect().x - 50,
          // };

          // //setNavNumber(navState);

          // navBtn.style.backgroundColor = "var(--kk-color-Ymain)";

          // navSpan.scrollTo({
          //   left: navState.navScroll,
          //   //behavior:'smooth',
          // });

          FocusBtn = navBtn;
        }
        //document.getElementById('navBtn'+)
      } else {
        navBtn.style.backgroundColor = "#f2f2f2";
        navBtn.children[0].style.color = "#545454";
      }
    }
    if (FocusBtn) {
      FocusBtn.style.backgroundColor = "var(--kk-color-Ymain)";
      FocusBtn.children[0].style.color = "white";
      navSpan.scrollTo({
        left: navSpan.scrollLeft + FocusBtn.getBoundingClientRect().x - 50,
        //behavior:'smooth',
      });
    }
  };
  useEffect(() => {
    if (state) {
      if (state.shopInfo) {
        if (
          state.shopInfo.c2_idx == SHOP_C2_IDX_MAP["beauty"] ||
          state.shopInfo.c2_idx == SHOP_C2_IDX_MAP["clothes"] ||
          state.shopInfo.c2_idx == SHOP_C2_IDX_MAP["movie"]
        ) {
          setIsCustomTable(true);
        } else {
          setIsCustomTable(false);
        }
        getShopMenuList(state.shopInfo.s_idx)
          .then((res) => {
            if (res.data.length > 0) {
              setMenuList(res.data);
              set_scroll_position_list(res.data);
              //set_scroll_position_list(res.data);
              // setScrollPostionList(()=>{
              //   menuList.map((data,index)=>{

              //   })
              // })
            } else {
              // 메뉴가 없는 매장처리
            }
          })
          .catch((err) => {
            serverErrorHandler(err);
          });
        // 지호 220706 가게정보보기
        getShopInfo(state.shopInfo.s_idx)
          .then((res) => {
            if (res.data.length !== null) {
              stateUtilityInstance.setMobileHeaderTitle(res.data.s_name);

              let reviewCnt = res.data.total_cnt;
              if (!reviewCnt) {
                reviewCnt = 0;
              }

              let reviewStar = res.data.total_star;
              if (!reviewStar) {
                reviewStar = 0;
              }

              let reviewAvg =
                Math.round(((reviewStar * 1) / (reviewCnt * 1)) * 2) / 2;
              if (!reviewAvg) {
                reviewAvg = 0;
              }

              res.data.reviewCnt = reviewCnt;
              res.data.reviewStar = reviewStar;
              res.data.reviewAvg = reviewAvg;

              setShopInfo(res.data);
              setIsLike(res.data.s_is_scrap > 0);
            } else {
              // 메뉴가 없는 매장처리
            }
          })
          .catch((err) => {
            serverErrorHandler(err);
          });
      }
    } else {
      navigate("/order");
    }
  }, []);

  useEffect(() => {
    console.log("reload");
    console.dir(basketReload);
    basketReload();
  }, [basketList]);

  useEffect(() => {
    console.log("스크롤 useEffect");
    const throttle = throttleFunction(handleScroll, 300);
    window.addEventListener("scroll", throttle);
    return () => {
      window.removeEventListener("scroll", throttle);
    };
  }, [scroll_position_list]);

  // useEffect(() => {
  //   //shopInfo.s_name
  //   if(menuList && shopInfo)
  //     stateUtilityInstance.setMobileHeaderTitle(shopInfo.s_name);
  // });

  const basketDeleteEvent = (s_idx) => {
    let rqArg = {};

    rqArg.sIdx = s_idx;

    return delBasket(rqArg)
      .then((res) => {
        console.log(res);
        console.log("삭제 성공");

        return true;

        //setBasketList(newBasketList);
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };
  const basketAddClickEvent = async (scheduleTime) => {
    let menuTempArr = [];
    let totAmt = 0;
   
    const targetElement = menuRef.current.querySelectorAll(".menu-article");
    targetElement.forEach((ele) => {
      let menuTemp = {
        scheduleTime: scheduleTime,
        m_idx: ele.dataset.midx,
        m_count: 0,
        m_name: "",
        m_price: ele.dataset.price * 1,
        optionCateArray: [],
        totalPrice: 0,
        table_name: shopInfo.table_name,
      };

      // 메뉴 타입이 체크박스
      const checkBox = ele
        .querySelector("span.menu-function")
        ?.querySelector("input").checked;

      if (checkBox) {
       
        menuTemp.m_count = 1;
        menuTemp.m_name = ele.querySelector("p.menu-name").innerText;

        // 메뉴에 해당하는 옵션들 가져오기
        ele.querySelectorAll("ul").forEach((opt) => {
          let moctemp = {
            moc_idx: opt.dataset.mocidx,
            moc_name: opt.querySelector("span.order-frame-mini").innerText,
            optionArray: [],
          };
          // 옵션이 라디오박스면
         // input:checked 된 값만 가져오기
           
           if (opt.className.includes("radio")) {
          
            const radioTarget = opt.querySelector("input:checked");
        //const radioTargetAll =  opt
        //    .querySelectorAll("div.radio-select-box")
         //   ?.querySelectorAll("input");
         /*   const radioTargetAll = opt.querySelectorAll('input[name="size-check-16"]');
            var radioTarget;
              console.log(radioTargetAll);
              for(let i=0; i<radioTargetAll.length; i++){
                if(radioTargetAll[i].checked){
                  radioTarget=radioTargetAll[i];
                  break;
                }
              }*/
            //let radioTarget=radioTargetAll[0];
            if (radioTarget) {
              
              moctemp.optionArray.push({
                mo_idx: radioTarget.dataset.moidx,
                mo_count: 1,
                mo_price: radioTarget.dataset.price * 1,
                mo_name: radioTarget.nextElementSibling.innerText,
              });
              menuTemp.optionCateArray.push(moctemp);
              menuTemp.totalPrice += radioTarget.dataset.price * 1;

              totAmt += radioTarget.dataset.price * 1;
            }
            // 옵션이 숫자박스면
          } else {
            
            opt.querySelectorAll("li").forEach((addEle) => {
              const optCount = addEle.querySelector("p.count-num")?.innerText;
              if (!isNaN(optCount) && optCount > 0) {
                moctemp.optionArray.push({
                  mo_idx: addEle.querySelector("p.count-num").dataset.moidx,
                  mo_count: optCount * 1,
                  mo_price:
                    addEle.querySelector("p.count-num").dataset.price * 1 ,
                  mo_name: addEle.querySelector("p.count-num").dataset.name,
                });

                totAmt +=
                  addEle.querySelector("p.count-num").dataset.price *
                  (optCount * 1);
                menuTemp.totalPrice +=
                  addEle.querySelector("p.count-num").dataset.price *
                  (optCount * 1);
                addEle.querySelector("p.count-num").innerText = "0";
              }
            });
            if (moctemp.optionArray.length > 0) {
              menuTemp.optionCateArray.push(moctemp);
            }
          }
        });
        menuTemp.totalPrice += ele.dataset.price * 1;
        totAmt += ele.dataset.price * 1;
        ele.querySelector("span.menu-function").querySelector("input").click();
      }

      // 메뉴 타입이 숫자형
      const countBox = ele
        .querySelector("span.item-count-box")
        ?.querySelector("p").innerText;

      if (!isNaN(countBox) && countBox > 0) {
        menuTemp.m_count = countBox * 1;
        menuTemp.m_name = ele.querySelector("p.menu-name").innerText;
        menuTemp.totalPrice += ele.dataset.price * (countBox * 1);
        totAmt += ele.dataset.price * (countBox * 1);
        ele.querySelector("span.item-count-box").querySelector("p").innerText =
          "0";
      }

      // if (totalPrice > 0) {
      //   console.log("total price : ", totalPrice);
      // }
      if (menuTemp.m_count > 0) {
        // totAmt += ele.dataset.price * 1;
        // menuTemp.totalPrice += ele.dataset.price * 1;

        menuTempArr.push(menuTemp);
      }
    });

    if (menuTempArr.length == 0) {
      return false;
    }

    // 매장영업중인지 체크

  /*  if (shopInfo.is_run < 1 || shopInfo.is_rest > 0) {
      alert("현재 영업중이 아닙니다.");
      return false;
    }

    if (shopInfo.used_schedule > 0) {
      if (!scheduleTime) {
        return false;
      }
    }*/

    if (basketCount > 0) {
      console.log(
        "basketShopIdx :" +
          stateUtilityInstance.getPropertyByName("basketShopIdx")
      );
      console.log("basketShopIdxNew :" + shopInfo.s_idx);

    /*  if (
        stateUtilityInstance.getPropertyByName("basketShopIdx") !=
        shopInfo.s_idx
      ) {
        if (
          window.confirm(
            "다른매장의 상품이 있습니다.\n장바구니를 비우고 새로 담으시겠습니까?"
          )
        ) {
          if (
            await basketDeleteEvent(
              stateUtilityInstance.getPropertyByName("basketShopIdx")
            )
          ) {
            setBasket({ sIdx: shopInfo.s_idx, rows: menuTempArr })
              .then((res) => {
                basketReload();
              })
              .catch((err) => {
                console.error(err);
                serverErrorHandler(err);
              });

            // setBasketList({ sIdx: shopInfo.s_idx, rows: newBasketList });
            setTotalAmt(totalAmt + totAmt);
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else { */
      await setBasket({ sIdx: shopInfo.s_idx, rows: menuTempArr })
          .then((res) => {
            basketReload();
          })
          .catch((err) => {
            console.error(err);
            serverErrorHandler(err);
          });

        // setBasketList({ sIdx: shopInfo.s_idx, rows: newBasketList });
        setTotalAmt(totalAmt + totAmt);
        return true;
    //  }
    } else {
      await setBasket({ sIdx: shopInfo.s_idx, rows: menuTempArr })
        .then((res) => {
          basketReload();
        })
        .catch((err) => {
          console.error(err);
          serverErrorHandler(err);
        });

      // setBasketList({ sIdx: shopInfo.s_idx, rows: newBasketList });
      setTotalAmt(totalAmt + totAmt);
      return true;
    }
  };

  const basketClickEvent = async () => {
    if (shopInfo.used_schedule == 1) {
      setIsSchedule(true);
    } else {
      if (await basketAddClickEvent()) {
        if (isMobile) {
          console.log("장바구니 담기 성공");
          stateUtilityInstance.setPropertyValue(
            "basketShopIdx",
            shopInfo.s_idx
          );
        } else {
          if (
            window.confirm(
              "장바구니에 추가하였습니다.\n장바구니로 이동하시겠습니까?"
            )
          ) {
            navigate("/order/confirm");
          }
        }
      } else {
        if (!isMobile) {
          if (window.confirm("장바구니로 이동하시겠습니까?")) {
            navigate("/order/confirm");
          }
        }
      }
    }
    //basketReload();
  };

  const scheduleClickEvent = async() => {
    const selectScheduleTime = document.querySelector(
      'input[name="time-check"]:checked'
    )?.value;

    if (!calendarRef.current.dataset.year) {
      alert("예약 시간을 선택해 주세요.");
      return;
    }
    if (!calendarRef.current.dataset.month) {
      alert("예약 시간을 선택해 주세요.");
      return;
    }
    if (!selectDate) {
      alert("예약 시간을 선택해 주세요.");
      return;
    }
    if (!selectScheduleTime) {
      alert("예약 시간을 선택해 주세요.");
      return;
    }
    if (
     await basketAddClickEvent(
        `${calendarRef.current.dataset.year}-${calendarRef.current.dataset.month}-${selectDate} ${selectScheduleTime}:00`
      )
    ) {
      navigate("/order/confirm");
    }
  };

  const shopElement = (
    <section className="shop-menu-list-frame">
      <div className="shop-menu-list-inner">
        <div className="thumbnailimg-box">
          {shopInfo?.s_img ? (
            <img
              src={SHOP_IMG_PATH + shopInfo.s_img}
              alt="기본 이미지"
              loading="lazy"
            />
          ) : (
            // <img src={""} alt="기본 이미지" />
            ""
          )}
        </div>
        <div className="shop-menu-list">
          <div className="shop-menu-title">
            <h2>{shopInfo?.s_name}</h2>
            {shopInfo?.c2_idx == 20 && (
              <h5 style={{ textAlign: "center", marginTop: 10 }}>
                {shopInfo?.s_ad.split("\r\n").map((tag) => {
                  return <p>{tag}</p>;
                })}
              </h5>
            )}

            {shopInfo?.c2_idx != 20 && (
              <>
                <span className="shop-grade">
                  <p>
                    <StarCnt isAvg={true} avg={shopInfo?.reviewAvg} />
                  </p>
                  <p>{shopInfo?.reviewAvg}</p>
                  <p>({shopInfo?.reviewCnt})</p>
                </span>
                <span className="shop-cost">
                  <p>
                    <img src={timeImg} alt="평균 대기 시간"></img>
                  </p>
                  <p>{shopInfo?.s_delivery_time}분</p>
                  <p>
                    {" "}
                    | 배송비 {shopInfo?.min_t_price}원 ~ {shopInfo?.max_t_price}
                    원
                  </p>
                </span>
              </>
            )}
            {shopInfo?.c2_idx != 20 && (
              <div className="all-review-wrap">
                {!params["*"] && (
                  <button
                    onClick={() => setChangeTab(3)}
                    className="all-review"
                  >
                    전체리뷰
                  </button>
                )}
              </div>
            )}
          </div>
          <Routes>
            <Route path="detail" element={<Beautydetail />} />
            <Route
              path=""
              element={
                <ShopMenuInfo
                  shopInfo={shopInfo}
                  menuList={menuList}
                  menuRef={menuRef}
                  changeTab={changeTab}
                  setChangeTab={setChangeTab}
                  isCustomTable={isCustomTable}
                  setNavNumber={setNavNumber}
                  basketClickEvent={basketClickEvent}
                />
              }
            />
          </Routes>
        </div>
        {isSchedule && (
          <CateTypeBeautyDate
            selectDate={selectDate}
            setSelectDate={setSelectDate}
            shopInfo={shopInfo}
            calendarRef={calendarRef}
            setIsSchedule={setIsSchedule}
            scheduleClickEvent={scheduleClickEvent}
          />
        )}
      </div>
      {!params["*"] && changeTab == 1 && (
        <>
          {isMobile ? (
            <FollowBasketMobile
              isLike={isLike}
              setIsLike={setIsLike}
              menuList={menuList}
              shopInfo={shopInfo}
              basketClickEvent={basketClickEvent}
              basketCount={basketCount}
              navState={navState}
            />
          ) : (
            <FollowBasket
              menuList={menuList}
              basketClickEvent={basketClickEvent}
              basketCount={basketCount}
            />
          )}
        </>
      )}
    </section>
  );

  return (
    <>
      {shopInfo && menuList ? (
        shopElement
      ) : (
        <div>
          <NewLoading tabState={0} index={0} />
        </div>
      )}
    </>
  );
};

export default Shop;
